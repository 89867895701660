import { Button, Flex, Modal, theme } from "antd";
import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Form,
  Input,
} from "antd";
import RoleSelector from "../../../Components/common/RoleSelector";
import { useDispatch, useSelector } from "react-redux";
import { getEditedUser, setEditedUser } from "../../../store/userSlice";
import { RootState } from "../../../store/store";
import { CreateUserType, UpdateUserType, User } from "../../../types/User.type";
import { useCreateUserMutation, useUpdateUserMutation } from "../../../store/userApiSlice";
import { UserRoleEnum } from "../../../types/UserRole.enum";
import { FieldType } from "../../login/Login";
import { MaskedInput } from "antd-mask-input";
import { StatusUserEnum } from "../../../types/Users.type";
import UserStatusSelector from "../../../Components/common/UserStatusSelector";

import "./UserEditor.scss"
import { UserAvatarUpload } from "./UserAvatarUpload";

const FormItem = Form.Item;

type PropsType = {
  modalVisible: boolean;
  userId?: number | null;
  closeModal: () => void;
};

const UserEditor: React.FC<PropsType> = ({
  modalVisible: modalVisible,
  userId,
  closeModal,
}) => {
  const dispatch = useDispatch()
  const [openModal, setOpenModal] = useState(modalVisible);
  const user = useSelector<RootState, User|null>(state=>getEditedUser(state))
  const { token: { colorText, colorTextDisabled, colorBgContainer, colorBgContainerDisabled, colorBorder : colorBorderBg, controlItemBgActive } } = theme.useToken();
  let currentAlgorithm = useSelector((state: RootState) => state.designMode.currentAlgorithm)

  let imageInitData = (user && user?.profile?.image_id && user?.profile?.avatar) ? {id: user.profile.image_id, url: user.profile.avatar}:undefined

  const [ status, setStatus ] = useState(user?.status||undefined)
  const [ image, setImage ] = useState<{id: number, url: string}|undefined>(imageInitData)
  const [ role, setRole ] = useState(user?.role||UserRoleEnum.Member)
  const [ phone_number, setPhonenumber ] = useState(user?.phone_number||'')
  const [ first_name, setFirstname ] = useState(user?.profile?.first_name||'')
  const [ last_name, setLastname ] = useState(user?.profile?.last_name||'')
  const [ password, setPassword ] = useState('')
  const [createUser, createResult] = useCreateUserMutation()
  const [updateUser, upodateResult] = useUpdateUserMutation()

  useEffect(() => {
    setOpenModal(modalVisible);
  }, [modalVisible]);

  const ok = () => {
    const userNew: Partial<User> = {
      ...user,
      phone_number,
      role,
      profile:{
        ...user?.profile,
        first_name,
        last_name,
        
      }
    }
    dispatch(setEditedUser(userNew))
    if(userNew.id){
      const payload: UpdateUserType = {
        id: userNew.id,
        role: role,
        first_name: first_name,
        last_name: last_name,
        password: password.length>=6?password:undefined,
        status: status,
        image_id: image?image.id:undefined
      } 
      updateUser(payload).then(()=>{
        closeModal();
      })
    } else {
      const payload: CreateUserType = {
        phone_number: phone_number,
        role: role,
        first_name: first_name,
        last_name: last_name,
        password: password,
        status: status,
        image_id: image?image.id:undefined
      } 
      createUser(payload).then(()=>{
        closeModal();
      })
    }
    
  };
  const cancel = () => {
    closeModal();
  };

  const statusChanged = (status: StatusUserEnum)=> setStatus(status)

  const roleChanged = (role: UserRoleEnum)=> setRole(role)
  const phoneChanged =(e: ChangeEvent<HTMLInputElement>) => setPhonenumber(e.currentTarget.value)
  const firstnameChanged = (e: ChangeEvent<HTMLInputElement>) => setFirstname(e.currentTarget.value)
  const lastnameChanged = (e: ChangeEvent<HTMLInputElement>) => setLastname(e.currentTarget.value)
  const passwordChanged = (e: ChangeEvent<HTMLInputElement>) => setPassword(e.currentTarget.value)
  const onUploadComplete = (e: any) => setImage(e)
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 14,
        offset: 6,
      },
    },
  };
  return (
    <>
      <Modal
        getContainer={()=>{return document.getElementById('modals_container')||document.body}}  
        onCancel={cancel}
        className={'userEditorModal ' + currentAlgorithm}
        title={(user?.id)?"Редактирование пользователя":'Новый пользователь'}
        wrapClassName="vertical-center-modal"
        open={modalVisible}
        footer={<><Button onClick={cancel}>Отмена</Button>
        <Button type="primary" onClick={ok}>Сохранить</Button></>}>
        <Form layout="vertical">
        <Flex style={{width: '100%', justifyContent: 'center', padding: '12px'}}>
          <UserAvatarUpload value={image} onUploadComplete={onUploadComplete}  />
        </Flex>

            <Form.Item {...formItemLayout} 
              label="Номер телефона"
              name="phone_number"
              initialValue={phone_number}
              rules={[{ required: true, message: 'Please input your phone number!' }]}
            >
              <MaskedInput className="inputMaskedInput"
               disabled={user?.id!=null} onChange={phoneChanged} mask={'+70000000000'} />
            </Form.Item>


          
          <FormItem
            {...formItemLayout}
            label={
              <span>
                Имя&nbsp;
              </span>
            }
            hasFeedback>
             <Input value={first_name} onChange={firstnameChanged}/>
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={
              <span>
                Фамилия&nbsp;
              </span>
            }
            hasFeedback>
            <Input value={last_name} onChange={lastnameChanged}/>
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Роль"
            hasFeedback
          >
            <RoleSelector value={role} onChange={roleChanged}/>
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Статус"
            hasFeedback
          >
            <UserStatusSelector value={status} onChange={statusChanged}/>
          </FormItem>
          {[UserRoleEnum.Admin, UserRoleEnum.SuperAdmin].includes(role) && 
          <FormItem
            
            {...formItemLayout}
            label={
              <span>
                Пароль&nbsp;
              </span>
            }
            hasFeedback>
            <Input value={password} onChange={passwordChanged}/>
          </FormItem>
          }
        </Form>
      </Modal>
    </>
  );
};

export default UserEditor;
