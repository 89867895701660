import React, { useEffect, useState } from "react";
import { MarkdownRenderer } from "../../../Components/common/MarkdownRenderer";
import { useGetFaqPageQuery } from "../../../store/faqApiSlice";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { Empty } from "../../../Components/common/Empty";



export const FaqUser: React.FC = () => {
  const params = useParams()
  const location = useLocation()
  const [text, setText] = useState<string>('')
  const { data, isLoading }  = useGetFaqPageQuery(params['*'])
  useEffect(()=>{
    if(data && !isLoading){
      setText(data.text||'')
    } else {
      setText('')
    }
  }, [data, isLoading])


  if(location.pathname === '/admin/faq') {
    return <Navigate to='/admin/faq/' />
  }
  return <div>
    {text && 
      <MarkdownRenderer text={text} />
    }
    {!text &&
      <Empty title="Для этой страницы справки еще нет" subtitle="&nbsp;"/>
    }
  </div>  
}