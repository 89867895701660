import { apiSlice } from "../api/apiSlice";
import { VizardType } from "../types/Vizard.type";

export const vizardApiSlice = apiSlice
  .injectEndpoints({
    endpoints: builder => ({
      createProject: builder.mutation({
        query: params => ({
          url: '/api/webchat/vizard/create',
          body: params,
          method: 'POST'
        })
      }),
      getProject: builder.query<VizardType, {id: any}>({
        query: params => ({
          url: `/api/webchat/vizard/project/${params.id}`,
        }),
        providesTags: (result, error, arg) => result ? [{ type: 'Vizard' as const, id: result.id }, 'Vizard']
            : ['Vizard'],
      }),
      getProjects: builder.query<VizardType[], {id: any}>({
        query: params => ({
          url: '/api/webchat/vizard/list',
          params: params
        }),
        providesTags: (result, error, arg) => result ? [...result.map(({ id }) => ({ type: 'Vizard' as const, id })), 'Vizards']
            : ['Vizards'],
      }),
      getProjectResponse: builder.mutation({
        query: params => ({
          url: `/api/webchat/vizard/response/${params.id}`,
        })
      }),
      getProjectDownloadsTest: builder.mutation({
        query: params => ({
          url: `/api/webchat/vizard/downloads/${params.id}`,
        })
      }),
      toggleVizardVideoFavorites: builder.mutation({
        query: params => ({
          url: `/api/webchat/vizard/video/favorites/toggle/${params.id}`,
          method: 'POST'
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'Vizard' as const, id: arg.vizard_id }]
      }),
      deleteVizardVideo: builder.mutation({
        query: params => ({
          url: `/api/webchat/vizard/video/delete/${params.id}`,
          method: 'DELETE'
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'Vizard' as const, id: arg.vizard_id }]
      })
    })
  })

  export const {
    useCreateProjectMutation,
    useGetProjectsQuery,
    useGetProjectQuery,
    useGetProjectResponseMutation,
    useGetProjectDownloadsTestMutation,
    useDeleteVizardVideoMutation,
    useToggleVizardVideoFavoritesMutation
  } = vizardApiSlice