import React from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

type PropsType = {
  className?: string
}

export const IconDownload: React.FC<PropsType> = ({className})=>{
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
  );

  let classNames: Array<string> = ['customIcon', currentAlgorithm]
  if(className) classNames.push(className)

return <svg className={classNames.join(' ')} width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M1.41667 8.91667C1.41667 9.561 1.939 10.0833 2.58333 10.0833L8.41667 10.0833C9.061 10.0833 9.58333 9.561 9.58333 8.91667L9.58333 8.33333C9.58333 8.01117 9.8445 7.75 10.1667 7.75C10.4888 7.75 10.75 8.01117 10.75 8.33333L10.75 8.91667C10.75 10.2053 9.70533 11.25 8.41667 11.25L2.58333 11.25C1.29467 11.25 0.25 10.2053 0.25 8.91667L0.25 8.33333C0.25 8.01117 0.511167 7.75 0.833333 7.75C1.1555 7.75 1.41667 8.01117 1.41667 8.33333L1.41667 8.91667ZM2.75419 5.58752C2.98199 5.35971 3.35134 5.35971 3.57915 5.58752L4.91667 6.92504L4.91667 1.33333C4.91667 1.01117 5.17783 0.75 5.5 0.75C5.82216 0.75 6.08333 1.01117 6.08333 1.33333L6.08333 6.92504L7.42085 5.58752C7.64866 5.35971 8.01801 5.35971 8.24581 5.58752C8.47362 5.81533 8.47362 6.18467 8.24581 6.41248L5.91248 8.74581C5.68467 8.97362 5.31533 8.97362 5.08752 8.74581L2.75419 6.41248C2.52638 6.18467 2.52638 5.81533 2.75419 5.58752Z" fill="#EAEAEC"/>
</svg>
}
