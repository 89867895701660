import { apiSlice } from '../api/apiSlice'

export const generationsLogsSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getGenerationsLogs: builder.query({
            query: (params) => ({
                url: '/api/generations/logs',
                method: 'GET',
                params
            })
        }),
        
    })
})

export const {useGetGenerationsLogsQuery} = generationsLogsSlice

export default generationsLogsSlice.reducer