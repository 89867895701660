import React from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

type PropsType = {
    className?: string
}

export const IconVideoPlay: React.FC<PropsType> = ({className}) => {
    let currentAlgorithm = useSelector(
        (state: RootState) => state.designMode.currentAlgorithm
    );

    let classNames: Array<string> = ['customIcon', currentAlgorithm]
    if(className) classNames.push(className)

    return <svg className={classNames.join(' ')}  width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.59185 1.16675H5.40685V3.71008H8.59185V1.16675Z" fill="#7479F6"/>
<path d="M9.46685 1.16675V3.71008H12.7568C12.4593 2.10591 11.2752 1.17258 9.46685 1.16675Z" fill="#7479F6"/>
<path d="M1.16602 4.58508V9.44425C1.16602 11.5676 2.43185 12.8334 4.55518 12.8334H9.44352C11.5668 12.8334 12.8327 11.5676 12.8327 9.44425V4.58508H1.16602ZM8.42268 9.43841L7.20935 10.1384C6.95268 10.2842 6.70185 10.3601 6.46852 10.3601C6.29352 10.3601 6.13602 10.3192 5.99018 10.2376C5.65185 10.0451 5.46518 9.64841 5.46518 9.13508V7.73508C5.46518 7.22175 5.65185 6.82508 5.99018 6.63258C6.32852 6.43425 6.76018 6.46925 7.20935 6.73175L8.42268 7.43175C8.87185 7.68841 9.11685 8.05008 9.11685 8.44091C9.11685 8.83175 8.86601 9.17591 8.42268 9.43841Z" fill="#7479F6"/>
<path d="M4.53185 1.16675C2.72352 1.17258 1.53935 2.10591 1.24185 3.71008H4.53185V1.16675Z" fill="#7479F6"/>
</svg>
}