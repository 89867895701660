import React from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

type PropsType = {
    className?: string
}

export const IconPause: React.FC<PropsType> = ({className}) => {
    let currentAlgorithm = useSelector(
        (state: RootState) => state.designMode.currentAlgorithm
    );

    let classNames: Array<string> = ['customIcon', currentAlgorithm]
    if(className) classNames.push(className)
    
      return<svg className={classNames.join(' ')} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.2125 10.1475V1.8525C5.2125 1.065 4.88 0.75 4.04 0.75H1.9225C1.0825 0.75 0.75 1.065 0.75 1.8525V10.1475C0.75 10.935 1.0825 11.25 1.9225 11.25H4.04C4.88 11.25 5.2125 10.935 5.2125 10.1475Z" fill="#7479F6"/>
<path d="M11.25 10.1475V1.8525C11.25 1.065 10.9175 0.75 10.0775 0.75H7.96C7.12583 0.75 6.7875 1.065 6.7875 1.8525V10.1475C6.7875 10.935 7.12 11.25 7.96 11.25H10.0775C10.9175 11.25 11.25 10.935 11.25 10.1475Z" fill="#7479F6"/>
</svg>

}

