import React, { useState } from "react";
import { ImageViewer } from "../common/ImageViewer";
import { useDeleteChatMutation, useToggleFavoriteMutation } from "../../store/webchatApiSlice";
import { ExclamationOutlined, LoadingOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { IconStarSelected } from "../Icons/IconStarSelected";
import { IconStar } from "../Icons/IconStar";
import { IconLinearEye } from "../Icons/IconLinearEye";
import { IconCross } from "../Icons/IconCross";

import './ChatImageCard.scss'
import { Popconfirm, Popover } from "antd";
import { IconDownload } from "../Icons/IconDownload";
import { ChatType } from "../../types/Chat.type";

type PropsType = {
    item: ChatType,
    mode: string
}
export const ChatImageCard: React.FC<PropsType> =({item, mode})=>{
    const [ toggleFavoriteLoading, setTogglingFavoriteLoading ] = useState<boolean>(false)
    const [ deleteChatMutation ] = useDeleteChatMutation()
    const [ toggleFavoriteMutation ] = useToggleFavoriteMutation()
    const [ openFull, setOpenFull ] = useState(null)

    const downloadImage = (url: any)=>()=>{
      if(!url) return
      window.location.assign(url)
    }
    const deleteChat = ()=>{
      // console.log(item)
      deleteChatMutation({chat_id: item.id})
    }
    const openImagePreview = (item: any)=>()=>{
      if(!item.webchat_result_image) return
      setOpenFull(item.webchat_result_image.id)
    }
    const toggleFavorite = (item: any)=>()=>{
      setTogglingFavoriteLoading(true)
      toggleFavoriteMutation({chat_id: item.id}).finally(()=>{
        setTogglingFavoriteLoading(false)
      })
    }
    
    return <div key={item.id} className="chatMessageImageCard">
    <div className="chatMessageImageCardImage">
        { item.state==='loading' && <div className="imageLoader"><LoadingOutlined style={{ fontSize: '64px', color: '#08c' }} /></div>}
        { item.state==='idle' && <ImageViewer url={item.webchat_result_image?.url} width="239px" 
            open={openFull===item.webchat_result_image?.id} close={()=>{setOpenFull(null)}} />
        }
        { item.state === 'error' && <div className="imageLoader">
          <Popover content={item.webchat_result_text}title={'Ошибка генерации изображения'}>
            <ExclamationOutlined  style={{ fontSize: '64px', color: '#08c' }} />
          </Popover>
          </div>
        }
    </div>
    <div className="chatMessageImageCardTitle">{item.name}</div>
    <div className="chatMessageImageCardIcons">
      { mode ==='user' && <div className="chatMessageImageCardIconBtn" onClick={toggleFavorite(item)}>
      { toggleFavoriteLoading? <LoadingOutlined /> :
      (item.is_favorite? <IconStarSelected /> : <IconStar />)}
      </div>
      }
      { mode ==='user' &&  <div className="chatMessageImageCardIconBtnGap"></div> }
      <div className={"chatMessageImageCardIconBtn " + (item.webchat_result_image?'':' disabled')} onClick={downloadImage(item.webchat_result_image?.url)}>
        <IconDownload />
        </div>
      <div className="chatMessageImageCardIconBtnGap"></div>
      <div className={"chatMessageImageCardIconBtn " + (item.webchat_result_image?'':' disabled')} onClick={openImagePreview(item)}><IconLinearEye /></div>
      <div className="chatMessageImageCardIconBtnGap"></div>
      <div className="chatMessageImageCardIconBtn">
          <Popconfirm style={{width: '200px'}}
                title="Удалить генерацию? Вы уверены?"
                placement="topRight"
                onConfirm={deleteChat}
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}>
                  <div>
                    <IconCross />
                  </div>
              </Popconfirm>
        </div>
    </div>
  </div>
}