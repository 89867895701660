import React from "react";

import "./VizardVideoPreviewCard.scss"
import { VideoPlayer } from "../../../Components/VideoPlayer/VideoPlayer";
import { Popconfirm, Progress } from "antd";
import { IconStar } from "../../../Components/Icons/IconStar";
import { IconDownload } from "../../../Components/Icons/IconDownload";
import { IconCross } from "../../../Components/Icons/IconCross";
import { useDeleteVizardVideoMutation, useToggleVizardVideoFavoritesMutation } from "../../../store/vizardApiSlice";
import { IconStarSelected } from "../../../Components/Icons/IconStarSelected";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

type PropsType = {
  id?: number
  vizard_id?: number
  is_favorite?: boolean
  name: string
  url?: string
  percent?: number
  isFavorite?: boolean
}

export const VizardVideoPreviewCard: React.FC<PropsType> = ({id, name, url, percent, is_favorite, vizard_id}) => {
  let currentAlgorithm = useSelector(
      (state: RootState) => state.designMode.currentAlgorithm
  );

  const [ deleteVideoMutation ] = useDeleteVizardVideoMutation()
  const [ toggleFavoriteMutation ] =  useToggleVizardVideoFavoritesMutation()

  const togleFavorites = async () => {
    if(!id) return
    await toggleFavoriteMutation({id, vizard_id})
  }
  const deleteVideo = async () => {
    if(!id) return
    await deleteVideoMutation({id, vizard_id})
  }
  const downloadVideo = () => {
    if(!url) return
    window.location.assign(url)
  }

  return <div className={"vizardVideoPreviewCard " + currentAlgorithm}>
    <div className="vizardVideoPreviewCardBody">
      {url && <VideoPlayer url={url} key={url} width="211px" height="211px"/>}
      {!url && percent && percent>-1 && percent<100 &&
      <Progress percent={percent} type="circle" />
      }

    </div>
    <div className="vizardVideoPreviewCardFooter">
      <div onClick={togleFavorites}>{(is_favorite? <IconStarSelected /> : <IconStar />)}</div>
      <div onClick={downloadVideo}><IconDownload /></div>
      <div>
        <Popconfirm style={{width: '200px'}}
          title="Удалить видео? Вы уверены?"
          placement="topRight"
          onConfirm={deleteVideo}
          icon={<QuestionCircleOutlined style={{ color: "red" }} />}>
            <div>
              <IconCross />
            </div>
        </Popconfirm>
        </div>
    </div>

  </div>
}