import React, { createRef, useEffect, useState } from "react";
import { useSendMessageMutation } from "../../store/webchatApiSlice";
import { Button, Form } from "antd";
import FormItem from "antd/es/form/FormItem";
import TextArea from "antd/es/input/TextArea";
import { errorDecode } from "../../api/errorDecode";
import { showNotification } from "../../store/notificationSlice";
import { useAppDispatch } from "../../store/store";
import { ChatMessages } from "./ChatMessages";


import './Chat.scss'
import { useLocation, useNavigate } from "react-router-dom";
import { IconSend } from "../Icons/IconSend";
// import { Mutex } from "async-mutex";

type PropsType = {
  neuronet_id: string | undefined
  option_id: string | undefined
  chat_id: string | undefined
  chatData?: any
}


export const Chat: React.FC<PropsType> = ({ neuronet_id, option_id, chat_id, chatData }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [send_text, setSendText] = useState<string>('');
  const [ sendMessage ] = useSendMessageMutation();
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  };

  useEffect(()=>{
    if(!chat_id  && chatData && chatData.prompt) {
      setSendText(chatData.prompt)
    } else {
      setSendText('')
    }
  }, [chat_id, chatData])

  // const mutex = new Mutex()
  const textRef = createRef<HTMLTextAreaElement>(); // like this

  useEffect(()=>{
    window.addEventListener('keyup', onkeyup)

    return ()=>{
      window.removeEventListener('keyup', onkeyup)
    }
  })

  const onkeyup = (key: KeyboardEvent)=>{
    if(key.key==='Enter' && key.shiftKey===false &&  key.ctrlKey===false && key.altKey===false){
      send()
    }
    if(key.key==='ArrowUp' && textRef.current?.focus){
    }
  }

  const send = () => {
    if (send_text.length === 0) {
      return dispatch(
        showNotification({
          type: "error",
          description: "Нельзя отправить пустое сообщение",
          message: "Внимание!",
        })
      );
    }
    let payload: any = { text: send_text };
    if (chat_id) {
      payload = {
        ...payload,
        chat_id: chat_id,
      };
    } else {
      payload = {
        ...payload,
        neuronet_id: Number.parseInt(neuronet_id || "0"),
        option_id: Number.parseInt(option_id || "0"),
        chat_id: 0,
      };
    }
    sendMessage(payload)
      .unwrap()
      .then((e) => {
        setSendText("");
        if (!chat_id) {
          navigate(`${location.pathname}/${e.webchat_id}`);
        }
      })
      .catch((error: any) => {
        const description = errorDecode(error);
        dispatch(
          showNotification({
            type: "error",
            description,
            message: "Ошибка сохранения",
          })
        );
      });
  };

  return (
    <><div 
      className='messageList'>
        {chat_id && 
          <ChatMessages chat_id={chat_id}/>
        }
      </div>
        <div className="sendMessageContainer">
          <div className="sendMessageForm">
            <Form style={{width: '100%'}}>
                
              <FormItem {...formItemLayout} style={{marginBottom: '12px', width:"100%"}}>
                <TextArea
                  style={{ border: 'none', width: '100%', backgroundColor: 'transparent'
                  }}
                  ref={textRef}
                  rows={3}
                  value={send_text}
                  onChange={(val) => setSendText(val.target.value)}
                  placeholder="Введите сообщение"
                />
              </FormItem>
            </Form>
          </div>
          <div className="sendMessageBtn">
                <Button
                  type="primary"
                  shape="circle"
                  size="large"
                  icon={<IconSend />}
                  onClick={send}></Button>
              

          </div>

        </div>
    </>
  );
};
