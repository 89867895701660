import React from "react";

type PropsType = {
  url: string
  width?: string
  height?: string
}


export const VimeoPlayer: React.FC<PropsType> = ({ url, width, height }) => {
  
  const vimeoUrl = new URL(url)
  if(vimeoUrl.origin==='https://vimeo.com'){
    const embedId = vimeoUrl.pathname

    return <div>
      <iframe 
        title="Vimeo video"
        src={`https://player.vimeo.com/video${embedId}`}
        width={width ? width : "640px"}
        height={height ? height : "225px"}
        allow="autoplay"></iframe>

    </div>  
  } 
  return <></>

}