import { Button, DatePicker, Flex, Pagination, Switch} from "antd";
import React, { useState } from "react";
import { useGetImagesPreviewQuery } from "../../../store/webchatApiSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import "./HistoryImageGenerations.scss"
import { Loader } from "../../../Components/common/Loader";
import { ChatImageCard } from "../../../Components/ChatImageCard/ChatImageCard";
import { useNavigate } from "react-router-dom";
import { NeuronetsSelector } from "../../../Components/common/NeuronetsSelector";
import { Empty } from "../../../Components/common/Empty";

const { RangePicker } = DatePicker;

type PropsType = {
  showFull: boolean
}

export const HistoryImageGenerations: React.FC<PropsType> = ({showFull}) => {
  const navigate = useNavigate();  
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
  );
  const [neronet, setNeronet] = useState<any>();
  const [range, setRange] = useState<any>();
  const [favorites, setFavorites] = useState<boolean>(false);
 
  const [ pagination, setPagination] = useState({page: 1, limit: showFull?20:10,  pageSizeOptions: [10, 20, 50, 100]})
  const { data, isLoading, isError  } = useGetImagesPreviewQuery({
    page: pagination.page,
    limit: pagination.limit,
    from: (range ? range[0]?.toDate().getTime() : undefined),
    to: (range ? range[1]?.toDate().getTime() : undefined),
    favorites,
    neuronet_id: (neronet ? neronet.split('_')[0] : undefined),
    option_id: (neronet && neronet.split('_').length===2)?neronet.split('_')[1]:undefined,

  });
  
  const setDateRange = (e: any) => {
    setRange(e);
  };

  if (isLoading) return <Loader />
  if(isError) return <>error</>
  const [messages, count] = data?data:[[],0]
  
  return (
    <div className={'historyImageGenerations '+currentAlgorithm} style={{ }}>
      {!showFull && (
      <div className="historyImageGenerationsHeader">
          <span>Генерация изображений</span>
          <span>
            {!showFull && <Button disabled={!isLoading && data && data[1]===0} onClick={()=>navigate('/lk/history/images')}>Смотреть все генерации</Button>}
          </span>
      </div>
      )}
      {showFull && (
        <div className={"historyImageGenerationsFilters "+currentAlgorithm}>
          <div className="historyImageGenerationsFiltersSelector">
            <span>Задача</span>
            <span>
              <NeuronetsSelector
                value={neronet}
                type="image"
                onChange={(value) => {
                  setNeronet(value);
                }}
              />
            </span>
          </div>
          <div className="historyImageGenerationsFiltersDatePicker">
            <span>Период</span>
            <span>
              <RangePicker value={range} onChange={setDateRange} />
            </span>
          </div>
          <div className="historyImageGenerationsFiltersCheckboxes">
            <Switch checked={favorites}  onChange={()=>setFavorites(!favorites)}/>
            <span>
              Избранное
            </span>
          </div>
        </div>
      )}
      {!isLoading && data && data[1]>0 && <>
        <Flex className='chatMessageImageContainer'>
            {messages &&
              messages.map((item: any) => {
                return <ChatImageCard mode='user' key={item.id} item={item} />
            })}
        </Flex>
        <Pagination onChange={(page: number, pageSize: number)=>{
          setPagination({...pagination,page, limit: pageSize})
        }} align="end" 
          defaultCurrent={pagination.page} 
          pageSize={pagination.limit} 
          total={count} 
          pageSizeOptions={pagination.pageSizeOptions}/>
      </>
      }
      { !isLoading && data && data[1]===0 &&
        <Empty />
      }
  </div>
  );
};
