import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { VizardDownloadsType } from "../types/Vizard.type";

interface initialStateType {
  downloading: {[key:number]: VizardDownloadsType[]}
}

const initialState: initialStateType = {
  downloading: {} 
}

const vizardSlice = createSlice({
    name: 'vizard',
    initialState,
    reducers:{
        setVizardDownloading: (state, action)=>{
            const downloading = action.payload.data
            const id = action.payload.id
            state.downloading[id] = downloading
        }
    }
})

export const { setVizardDownloading } = vizardSlice.actions
export const getVizardDowloadState = (state: RootState, id: any) => {
  if (state.vizardSlice.downloading[id]){
    return state.vizardSlice.downloading[id]
  }
  return undefined
}
export default vizardSlice.reducer
