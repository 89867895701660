import React, { useEffect, useState } from "react";
import { useDeleteFaqPageMutation, useGetFaqListQuery } from "../../../store/faqApiSlice";
import { Button, List, Pagination, Popconfirm } from "antd";
import { Link } from "react-router-dom";
import { CheckOutlined, DeleteOutlined, FileDoneOutlined, FileUnknownOutlined, UndoOutlined } from "@ant-design/icons";

import './FaqList.scss'

export const FaqList: React.FC = ()=>{
  const [pagination, setPagination] = useState<any>({
    total: 1,
    pageSize: 20,
    pageSizeOptions: [10, 20, 50, 100],
    current: 1,
  });
  const [ deleteFaqPageMutation ]= useDeleteFaqPageMutation()
  const { data, isLoading } = useGetFaqListQuery({
    page: pagination.current,
    limit: pagination.pageSize,
  })

  useEffect(()=>{
    if(data) setPagination({...pagination, total: data[1]})
  }, [data])

  const deleteItem = (item: any) => () => {
    deleteFaqPageMutation({id: item.id})
  }


  return <>
  {!isLoading && data && 
  <List 
    dataSource={data[0]}
    renderItem={(item: any)=><List.Item actions={[
      <Popconfirm
      title={item.deleted_at ?'Восстановить запись?':"Удалить запись?"}
      description="Вы уверены?"
      onConfirm={deleteItem(item)}
      okText="Да"
      cancelText="Нет"
    >
      <Button type='text'>
      {!item.deleted_at ? <DeleteOutlined />:<UndoOutlined />}
      </Button>
    </Popconfirm>
        
      ]}>
        <div className="faqListItem">
          <span>
            {item.is_published?<CheckOutlined />:<FileUnknownOutlined />}
          </span>
          <span>
            <Link className={'faqListItemLink'+ (item.deleted_at ? ' deleted' : '') + (item.is_published ? ' published' : '')} to={item.path}>{item.path?item.path:'Главная страница'}</Link>
          </span>

        </div>
        
      </List.Item>
    }
  />
  }
  <Pagination onChange={(page)=>{
            setPagination({...pagination,current: page})
          }} defaultCurrent={pagination.current} pageSize={pagination.pageSize} total={pagination.total} />
  </>
}