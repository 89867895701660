import React, { useState } from "react";

import './SupportRequestCard.scss'
import { SupportRequestItem } from "../../../types/SupportRequestItem.type";
import { Tag } from "antd";
import { SupportStatusEnum } from "../../../types/SupportStatus.enum";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { ArrowRightOutlined } from "@ant-design/icons";
import { SupportRequestDetails } from "./SupportRequestDetails";

type PropsType = {
  item: SupportRequestItem
}
export const SupportRequestCard: React.FC<PropsType> = ({item}) => {
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
  );

  const [ openDetails, setOpenDetails ] = useState<boolean>(false)

//  background: var(--Icon-icon-success, #68B274);

  return <div className={"supportRequestCard " + currentAlgorithm}>
    <div className="supportRequestCardHeader">
      <span className="supportRequestCardHeaderId">
      #{item.id}
      </span>
      <span className="supportRequestCardHeaderTags">
        
          {item.status===SupportStatusEnum.OPEN && <Tag className="statusOpenTag">Открыта</Tag>}
          {item.status===SupportStatusEnum.CLOSED && <Tag className="statusClosedTag">Закрыта</Tag>}
          
      </span>
    </div>
    <div className="supportRequestCardSubject">
    {item.subject}
    </div>
    <div className="supportRequestCardBody">
      <div className="supportRequestCardBodyText">
        {item.messages[0].text}
      </div>  
      <div>
        <ArrowRightOutlined onClick={()=>{setOpenDetails(true)}} />
      </div>
    </div>
    {openDetails && 
      <SupportRequestDetails open={openDetails} item={item} close={()=>{setOpenDetails(false)}} />
    }
  </div>

}
