import { Button, Drawer, Flex, Switch } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useRef, useState } from "react";
import { MarkdownRenderer } from "../../../Components/common/MarkdownRenderer";
import { Navigate, unstable_usePrompt, useLocation, useParams } from "react-router-dom";
import { useGetFaqPageQuery, useSaveFaqMutation } from "../../../store/faqApiSlice";
import { FaqList } from "./FaqList";
import { ArrowLeftOutlined, SaveOutlined } from "@ant-design/icons";

export const FaqAdmin: React.FC = ()=>{
  const params = useParams()
  const debounceRef  = useRef<NodeJS.Timeout>()
  const location = useLocation()
  const [openList, setOpenList] = useState<boolean>(false)
  const [text, setText] = useState<string>('')
  const [is_published, setIsPublished] = useState<boolean>(false)
  
  let [isChanged, setIsChanged] = useState(false);
  
  const { data, isLoading }  = useGetFaqPageQuery(params['*'])

  const [ saveFaqMutation ] = useSaveFaqMutation()

  useEffect(()=>{
    if(data && !isLoading){
      setText(data.text||'')
      setIsPublished(data.is_published)
    } else {
      setText('')
    } 

  }, [data, isLoading])

  useEffect(()=>{
    document.addEventListener('keydown', onkeyup)
    return ()=>{
      document.removeEventListener('keydown', onkeyup)
    }
  })

  const onkeyup = (key: KeyboardEvent)=>{
    console.log(key)
    if(key.ctrlKey===true && key.key==='s' && key.altKey===false && key.shiftKey===false ){
      key.preventDefault()
      key.stopPropagation()
      saveData()
    }

  }
  const saveData = ()=>{
    saveFaqMutation({path: params['*'], text, is_published})
    setIsChanged(false)
  }
  // useEffect(()=>{
  //   if(debounceRef.current){
  //     clearTimeout(debounceRef.current)
  //   }
  //   debounceRef.current = setTimeout(() => {
  //    saveData()
  //   }, 15000);
    
  // }, [text])


  if(location.pathname === '/admin/faq') {
    return <Navigate to='/admin/faq/' />
  }
  const handleChange = (e: any) => {
    setText(e.target.value)
    setIsChanged(true)
  }

  return (
    <div className="faqAdmin">
      <Flex style={{marginBottom: '12px'}}>Для редактирования используйте формат MarkDown</Flex>
      <Flex style={{ width: "100%", padding: "0px 12px", gap: '12px'}}>
        <span>
        <Button onClick={saveData} disabled={!isChanged}>
          <SaveOutlined /> Сохранить
        </Button>
        </span>
        <span>
          <Switch onClick={(e)=>{
            setIsPublished(e)
            setIsChanged(true)
          }} value={is_published} /> Опубликовано

        </span>
      </Flex>
      <Flex style={{ width: "100%", minHeight: "90%", padding: "0px 12px" }}>
        <Flex
          style={{
            width: "50%",
            minHeight: "100%",
            margin: "4px 12px 0px 0px",
          }}>
          <TextArea  style={{ border: '1px solid var(--Border-border-light, #DBDBE080)', width: '100%', backgroundColor: 'transparent'}}
                  value={text} onChange={handleChange} />
        </Flex>

        <Flex
          style={{
            width: "50%",
            minHeight: "100%",
            padding: "0px 12px",
            border: "1px solid var(--Border-border-light, #DBDBE080)",
            borderRadius: "8px",
          }}>
          <MarkdownRenderer text={text} />
        </Flex>
        <Flex
          onClick={() => {
            setOpenList(true);
          }}
          style={{
            position: 'absolute',
            right: '0px',
            width: "32px",
            height: "10%",
            alignItems: 'center',
            justifyContent: 'center',
            border: "1px solid  var(--Border-border-light, #DBDBE080)",
            borderRadius: "8px 0px 0px 8px",
          }}>
          <div>
            <ArrowLeftOutlined />
          </div>
        </Flex>
      </Flex>
      <Drawer
        title="Список статей"
        onClose={() => setOpenList(false)}
        open={openList}>
        <FaqList />
      </Drawer>
    </div>
  );
}