import React, {useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetLogsQuery } from "../../../store/logsSlice";
import { Table } from "antd";
import { useGetMessagesListQuery, useGetUserQuery } from "../../../store/userApiSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import './UserView.scss';

type PropsType = {

}

const UserView: React.FC<PropsType> = () => {
  const { id } = useParams()
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
  );

  const { data: userinfo, isLoading: userinfoLoading } = useGetUserQuery({user_id: id})

  const [logsPagination, setLogsPagination] = useState<any>({total:1, pageSize: 10, pageSizeOptions:[10,20,50,100], current: 1})
  const { data: logsData, error: logsError, isLoading: logsIsLoading, refetch: logsRefetch } = useGetLogsQuery({
    user_id: id, 
    page: logsPagination.current,
    limit: logsPagination.pageSize,
    q: "",
  })

  const [messagesPagination, setMessagesPagination] = useState<any>({total:1, pageSize: 10, pageSizeOptions:[10,20,50,100], current: 1})
  const { data: messagesData, error: messagesError, isLoading: messagesIsLoading, refetch: messagesRefetch } = useGetMessagesListQuery({
    user_id: id, 
    page: messagesPagination.current,
    limit: messagesPagination.pageSize,
    q: "",
  })

  useEffect(()=>{
    if (logsData)
    setLogsPagination({...logsPagination, total: logsData[1]})
  }, [logsData])

  useEffect(()=>{
    if (messagesData)
    setMessagesPagination({...messagesPagination, total: messagesData[1]})
  }, [messagesData])
   
  const logsColumns = [
    {
      title: "Дата",
      dataIndex: "created_at",
      key: "created_at",
      render: (text: string, record: any)=>{
        return (
            <div>
                <span>
                    {new Date(record.created_at).toLocaleDateString()}
                </span>
                <span style={{marginLeft: '8px'}}>
                    {new Date(record.created_at).toLocaleTimeString()}
                </span>
            </div>
        );
      }
    },
    {
      title: "Тип записи",
      dataIndex: "action",
      key: "action",
    },
    {
      title: "Описание",
      dataIndex: "description",
      key: "description",
    },
    // {
    // title: 'Действия',
    // dataIndex: 'operation',
    // render: (text: string, record: any) => {
    //     return (
    //         <div className="operations">
               
    //         </div>
    //     );
    //     }
    // },
  ];

  const messagesColumns = [
    {
      title: "Дата",
      dataIndex: "created_at",
      key: "created_at",
      render: (text: string, record: any)=>{
        return (
            <div>
                <span>
                    {new Date(record.created_at).toLocaleDateString()}
                </span>
                <span style={{marginLeft: '8px'}}>
                    {new Date(record.created_at).toLocaleTimeString()}
                </span>
            </div>
        );
      }
    },
    {
      title: "Текст сообщения",
      dataIndex: "text",
      key: "text",
    }
  ]

  const changedLogsTable = (e: any) => {
    setLogsPagination(e)
  };
  const changedMessagesTable = (e: any) => {
    setMessagesPagination(e)
  };
  const logs = logsData ? logsData[0]:[]
  
  return (
    
    <div className="userLogs">
      <div className="userLogsTitle">Логи пользователя 
        <span className="userLogsTitleUser"> 
          {userinfo?.profile.first_name} {userinfo?.profile.last_name}
        </span>
        </div>
        <Table
          bordered
          columns={logsColumns}
          dataSource={logs}
          pagination={logsPagination}
          loading={logsIsLoading}
          onChange={changedLogsTable}
          className={currentAlgorithm === "dark" ? "table_dark" : ""}
          rowKey={record => record.id}></Table>
        {!messagesError && !messagesIsLoading && <Table
          bordered
          columns={messagesColumns}
          dataSource={messagesData[0]||[]}
          pagination={messagesPagination}
          loading={messagesIsLoading}
          onChange={changedMessagesTable}
          className={currentAlgorithm === "dark" ? "table_dark" : ""}
          rowKey={record => record.id}></Table>
        }
    </div>
  )
}


export default UserView