import { Button, Flex } from "antd";
import React from "react";
import { MessageType } from "../../types/Message.type";
import { MarkdownRenderer } from "../common/MarkdownRenderer";
import { SoundPlayer } from "../SoundPlayer/SoundPlayer";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { getPlayState, setPlayState, setPlayStop } from "../../store/audioPlayerSlice";
import { IconPlay } from "../Icons/IconPlay";
import { IconStop } from "../Icons/IconStop";

import "./ChatMessageUser.scss";

type PropsType = {
  message: MessageType;
};

export const ChatMessageUser: React.FC<PropsType> = ({ message }) => {
  const playerState = useAppSelector((state)=>getPlayState(state, message.id))
  const dispatch = useAppDispatch()

  const userName =
    (message.user?.profile?.first_name
      ? message.user?.profile?.first_name
      : "") + 
    (message.user?.profile?.last_name
      ? " " + message.user.profile.last_name
      : "");

  const created_at = new Date(message.created_at);
  const formatTime = (date: Date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return (
      (hours < 10 ? "0" + hours : hours) +
      ":" +
      (minutes < 10 ? "0" + minutes : minutes)
    );
  };
  const localDateTime = formatTime(created_at);

  const startPlayer = ()=>{
    if(playerState==='idle') {
      // setPlayerState('play')
      dispatch(setPlayState(message.id))
    } else {
      dispatch(setPlayStop(null))
    }
  }

  const stopPlayer = ()=>{
    dispatch(setPlayStop(null))
  }
  
  return (
    <div id={`msgid_${message.id}`} className="message messageUserContainer">
      <div className="messageUser">
        <div className="messageUserHeader">
        <span>{userName}</span>
        <span>{localDateTime}</span>
        </div>
        <div className="messasgeBodyContainer">
          { message.type==='text' &&
            <div className="messageText"><MarkdownRenderer text={message.text} /></div>
          }
          {message.type==='audio' && message.audio && message.audio.url && 
            <Flex style={{gap: '8px', alignItems: 'center'}}>
              <Flex>
                <Button onClick={startPlayer}>
                  {playerState==='idle' && <IconPlay />}
                  {playerState==='play' && <IconStop />}
                  
                </Button>
              </Flex>
              <Flex style={{width: '100%', height: '4px'}}>
               <SoundPlayer url={message.audio?.url} state={playerState} onStop={stopPlayer} />

              </Flex>

            </Flex>
          }
        </div>
      </div>
    </div>
  );
};
