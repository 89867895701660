import React from "react";
import { Button, Col, Modal, Row } from "antd";

import './GenerationsLogDetails.scss'

type PropsType = {
  record: any,
  close: ()=>void,
}

type ActionPropsType = {
  record: any
}

const TextRender: React.FC<ActionPropsType> = ({record}) => {
  return (<>
  <Row>
    <Col span={24}>{record.result}</Col>
  </Row>
  </>)
}


const ImageRender: React.FC<ActionPropsType> = ({record}) => {
  return (<>
  <Row>
  
    <Col span={24}><img src={record.result} width={640}></img></Col>
  </Row>
  </>)
}

const ErrorRender: React.FC<ActionPropsType> = ({record}) => {
  return (<>
  <Row>
    <Col span={24}>{record.error}</Col>
  </Row>
  </>)
}
// const Action_SEND_MESSAGE: React.FC<ActionPropsType> = ({action}) => {
//   return (<>
//   <Row>
//     { action.text && <><Col span={12}>Text</Col><Col span={12}>{action.text}</Col></> }
//     { action.image_url && <><Col span={12}>ImageUrl</Col><Col span={12}>{action.image_url}</Col></> }
//     { action.image_id && <><Col span={12}>ImageID</Col><Col span={12}>{action.image_id}</Col></> }
//   </Row>
//   </>)
// }


const GenerationsLogDetails: React.FC<PropsType> = ({record, close})=>{

  const ok = ()=>{
    close()
  }
  const cancel = ()=>{
    close()
  }

  return (
      <>
      <Modal 
        getContainer={()=>{return document.getElementById('modals_container')||document.body}}  
        title="Детали"
        wrapClassName="vertical-center-modal"
        width={680}
        open={true}
        onOk={() => ok()}
        onCancel={() => cancel()}
        footer={null}>
        { record && 
        <Row className="logDetailsContainer">
          <Col span={24}>
          <Row>
            <Col className='label' span={12}>ID</Col>
            <Col className='value' span={12}>{record.id}</Col>
          </Row>
          <Row className="horizontalspliter"></Row>
          <Row>
            <Col className='label' span={12}>Дата</Col>
            <Col className='value' span={12}>{record.created_at}</Col>
          </Row>
          <Row className="horizontalspliter"></Row>
          <Row>
            <Col className='label' span={12}>Алиас</Col>
            <Col className='value' span={12}>{record.alias}</Col>
          </Row>
          <Row className="horizontalspliter"></Row>
          <Row>
            <Col className='label' span={12}>Промпт</Col>
            <Col className='value' span={12}>
            {record.prompt}
            </Col>
          </Row>
          <Row className="horizontalspliter"></Row>
          <Row className="resultContainer">
            <Col span={24}>Результат</Col>
          </Row>
          {/* <Row className="horizontalspliter"></Row> */}
          <Row className="resultContainer">
            <Col span={24}>
              { record.output_type==='text' && record.status==='success' &&
                <TextRender record={record} />
              }
              { record.output_type==='image' && record.status==='success' &&
                <ImageRender record={record} />
              }

              { record.output_type==='video' && record.status==='success' &&
                <TextRender record={record} />
              }

              { record.output_type==='videos' && record.status==='success' &&
                <TextRender record={record} />
              }
              
              { record.output_type==='audio' && record.status==='success' &&
                <TextRender record={record} />
              }
              
              { record.output_type==='audio_recognition' && record.status==='success' &&
                <TextRender record={record} />
              }
              
              { record.status==='error' &&
                <ErrorRender record={record} />
              }
            
            </Col>
          </Row>
          </Col>
        </Row>  
        }
      </Modal>
    </>
  )

}

export default GenerationsLogDetails