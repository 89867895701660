import React from "react";

import "./VideoPlayer.scss";
import { useAppDispatch } from "../../store/store";
import { openVideoPlayer } from "../../store/videoPlayerSlice";

type PropsType = {
  url: string
  width?: string
  height?: string
};

export const VideoPlayer: React.FC<PropsType> = ({ url, width, height }) => {
  
  const dispatch = useAppDispatch()

    const handleOpenModal = () => {
      dispatch(openVideoPlayer({url}));
  };
  
  return (
    <div className="videoPlayer" style={{width: width ? width : "853", height: height ? height: "480"}}>
      <video
        src={url}
        controls={false}
        width={width ? width : "320px"}
        height={height ? height : undefined}
        onClick={handleOpenModal}
      />
    </div>
  );
};
