import { Button, Modal } from "antd";
import React from "react";
import { useGetChatMessagesListQuery } from "../../../store/webchatApiSlice";
import { ChatMessages } from "../../../Components/Chat/ChatMessages";
import { ChatType } from "../../../types/Chat.type";

type PropsType = {
  open: boolean,
  close: () => void,
  item: ChatType
}

export const HistoryAudioDialog: React.FC<PropsType> = ({open, close, item}) => {

  
  const { data } = useGetChatMessagesListQuery({ chat_id: item.id })
  
  if(!item) return <></>


  return <Modal 
      getContainer={()=>{return document.getElementById('modals_container')||document.body}}  
      open={open}
      onCancel={close}
      footer={null}
      title={<>История диалога</>}
      >

    <div style={{maxHeight: '80%', overflow: 'hidden', overflowY: 'scroll'}}>
      <ChatMessages chat_id={''+item.id} />
    </div>
  </Modal>
}