import { Input } from "antd";
import React from "react";
import {
  NeuronetAccountKey,
  NeuronetAccountKeySecret,
} from "../../../../types/SaveNeuronet.type";
import FormItem from "antd/es/form/FormItem";

type PropsType = {
  value: NeuronetAccountKey;
  onChange: (val: NeuronetAccountKey | NeuronetAccountKeySecret) => void;
};

export const AccountKey: React.FC<PropsType> = ({ value, onChange }) => {
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 3 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 21 },
    },
  };
  return (
    <>
      <FormItem {...formItemLayout} label={<span>Ключ&nbsp;</span>} hasFeedback>
        <Input value={value.key} onChange={(val)=>{onChange({...value, key: val.target.value})}}/>
      </FormItem>
    </>
  );
};
