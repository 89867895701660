import { Button, Card, Flex } from "antd";
import React from "react";
import {
  useGetProjectQuery,
  useGetProjectResponseMutation,
} from "../../../store/vizardApiSlice";
import { useParams } from "react-router-dom";
import { Loader } from "../../../Components/common/Loader";
import { VideoPlayer } from "../../../Components/VideoPlayer/VideoPlayer";
import { YouTubePlayer } from "../../../Components/VideoPlayer/YoutubePlayer";
import { GoogleDrivePlayer } from "../../../Components/VideoPlayer/GoogleDrivePlayer";
import { VimeoPlayer } from "../../../Components/VideoPlayer/VimeoPlayer";
import { useAppSelector } from "../../../store/store";
import { getVizardDowloadState } from "../../../store/vizardSlice";
import { IconTimer } from "../../../Components/Icons/IconTimer";
import "./LKVideoVizardView.scss";
import { VizardVideoPreviewCard } from "./VizardVideoPreviewCard";

const timeOptions: { [key: number]: string } = {
  0: "Авто",
  1: "До 30 секунд",
  2: "От 30 секунд до 60 секунд",
  3: "От 60 секунд до 90 секунд",
  4: "от 90 секунд до 3 минут",
};

const languageText: { [key: string]: any } = {
  ar: "Арабский",
  nl: "Голландский",
  en: "Английский",
  fr: "Французский",
  de: "Немецкий",
  hi: "Хинди",
  id: "Индонезийский",
  it: "Итальянский",
  ja: "Японский",
  ko: "Корейский",
  zh: "Мандарин (Китайский диалект)",
  pt: "Португальский",
  ru: "Русский",
  es: "Испанский",
  tr: "Турецкий",
  uk: "Украинский",
  vi: "Вьетнамский",
};

const statusText: {[key:string]: string} = {
  'New': 'Новый проект',
  'Created': 'Проект создан на сервере',
  'Error': 'Обработка завершилась с ошибкой',
  'Waiting': "Ожидание",
  'Downloading': 'Скачивание результатов обработки',
  'Done': 'Успешно обработано',
}

export const LKVideoVizardView: React.FC = () => {
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };
  const params = useParams();
  const downloadState = useAppSelector((state) =>
    getVizardDowloadState(state, params.id)
  );

  const { data, isLoading, isError } = useGetProjectQuery({ id: params.id });
  const [getProjectResponse] = useGetProjectResponseMutation();
  if (isLoading) {
    return <Loader />;
  }
  const canReRequest = () => {
    if (!data) return false;
    const updatedAt = new Date(data.updated_at).getTime() / 1000;
    if (
      updatedAt < new Date().getTime() / 1000 - 3600 * 3 &&
      ["Created", "Waiting"].includes(data.project_status)
    ) {
      return true;
    }
    return false;
  };
  const getResponse = () => {
    getProjectResponse(params);
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };
  const getLangStr = (lang: string): string => {
    return languageText[lang];
  };
  const getTimeStr = (time: number[]): string => {
    return time
      .map((t) => {
        return timeOptions[t];
      })
      .join("; ");
  };
  const getStatusStr = (status: string): string => {
    if(Object.keys(statusText).includes(status)){
      return statusText[status]
    }
    return ""
  }
  return (
    <div className="LKVideoVizardView">
      {data && (
        <div>
          {canReRequest() && (
            <Button onClick={getResponse}>Повторить попытку обработки</Button>
          )}
          <Card>
            <Flex vertical gap={24} wrap={"nowrap"} justify="space-between">
              <Flex vertical gap={"32px"}>
                <Flex gap={24}>
                  <div className="videoVizardViewTag">ID: {data.id}</div>
                  <div className="videoVizardViewTag">
                    <span>
                      <IconTimer /> {formatDate(data.created_at)}
                    </span>
                  </div>
                </Flex>

                <Flex gap={9}>
                  <div
                    className="videoVizardViewTag"
                    style={{ minWidth: "127px" }}>
                    <div>Язык</div>
                    <div>{getLangStr(data.language)}</div>
                  </div>
                  <div
                    className="videoVizardViewTag"
                    style={{ minWidth: "127px" }}>
                    <div>Формат</div>
                    <div>{data.format}</div>
                  </div>
                  <div
                    className="videoVizardViewTag"
                    style={{ minWidth: "127px" }}>
                    <div>Длительность</div>
                    <div>{getTimeStr(data.time)}</div>
                  </div>
                  <div
                    className="videoVizardViewTag"
                    style={{ minWidth: "127px" }}>
                    <div>Субтитры</div>
                    <div>{data.subtitle ? "Да" : "Нет"}</div>
                  </div>
                  <div
                    className="videoVizardViewTag"
                    style={{ minWidth: "127px" }}>
                    <div>Подсвечивать субтитры</div>
                    <div>{data.highlights ? "Да" : "Нет"}</div>
                  </div>
                </Flex>
              </Flex>
              <Flex vertical gap={24}>
                <Flex gap={24}>
                  <span>Статус</span>
                  <span>{getStatusStr(data.project_status)}</span>
                </Flex>
                {data.error_message && (
                  <Flex gap={24}>
                    <span>Сообщение об ошибке</span>
                    <span>{data.error_message}</span>
                  </Flex>
                )}
              </Flex>
              <Flex
                vertical
                className="videoVizardViewTag"
                style={{ maxHeight: "320px", padding: "24px 32px" }}>
                {data.videoType === 4 && (
                  <VimeoPlayer width="400"
                  height="225" url={data.source_video_url} />
                )}
                {data.videoType === 3 && (
                  <GoogleDrivePlayer width="400"
                  height="225" url={data.source_video_url} />
                )}
                {data.videoType === 2 && (
                  <YouTubePlayer
                    width="400"
                    height="225"
                    url={data.source_video_url}
                  />
                )}
                {data.videoType === 1 && (
                  <VideoPlayer width="400"
                  height="225" url={data.source_video_url} />
                )}
              </Flex>
            </Flex>
          </Card>
          {!data.error_message &&
            <Flex vertical gap={"24px"} className="LKVideoVizardViewResult">
              <div className="LKVideoVizardViewResultTitle">
                Результат
              </div>
              <Flex gap={"24px"} wrap={"wrap"} justify="left">
              {downloadState && downloadState.map(
                    (file: { name: string; percent: number }) => {
                      return (
                        <VizardVideoPreviewCard
                          name={file.name}
                          percent={Math.round(file.percent)}
                          key={file.name}
                        />
                        // <Flex vertical>
                        //   <div>
                        //     <div>{file.name}</div>
                        //     <Progress
                        //       percent={Math.round(file.percent)}
                        //       key={file.name}></Progress>
                        //   </div>
                        // </Flex>
                      );
                    }
                  )}
              {data.videos && data.videos.map((video) => {
                  return (
                    <>
                      <VizardVideoPreviewCard
                        name={video.meta.filename}
                        url={video.url}
                        id={video.id}
                        vizard_id={data.id}
                        is_favorite={video.is_favorite}
                        key={video.id}
                      />
                    </>
                  );
                })
              }
              </Flex>
            </Flex>
          }
          {/* {JSON.stringify(data)} */}
        </div>
      )}
    </div>
  );
};
