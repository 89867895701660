import { Button, DatePicker, Popconfirm, Switch, Table } from "antd";
import React, { useEffect, useState } from "react";
import {
  useDeleteChatMutation,
  useGetChatsQuery,
  useToggleFavoriteMutation,
} from "../../../store/webchatApiSlice";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

import { IconStar } from "../../../Components/Icons/IconStar";
import { IconCross } from "../../../Components/Icons/IconCross";
import "./HistoryVizardGenerations.scss";
import { Loader } from "../../../Components/common/Loader";
import { IconStarSelected } from "../../../Components/Icons/IconStarSelected";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { NeuronetsSelector } from "../../../Components/common/NeuronetsSelector";
import { Empty } from "../../../Components/common/Empty";
const { RangePicker } = DatePicker;

type PropsType = {
  showFull: boolean;
};

export const HistoryVizardGenerations: React.FC<PropsType> = ({ showFull }) => {
  const navigate = useNavigate();
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
  );
  const [favorites, setFavorites] = useState<boolean>(false);
  const [neronet, setNeronet] = useState<any>();
  const [range, setRange] = useState<any>();
  const [q, setSearchQuery] = useState<string>();
  const [deleteChatMutation] = useDeleteChatMutation();
  const [toggleFavoriteMutation] = useToggleFavoriteMutation();
  const [pagination, setPagination] = useState<any>({
    total: 1,
    pageSize: showFull ? 10 : 5,
    pageSizeOptions: [10, 20, 50, 100],
    current: 1,
  });
  const [tableSorter, setSorter] = useState<any>({
    field: "created_at",
    order: "descend",
  });
  const { data, isLoading } = useGetChatsQuery({
    page: pagination.current,
    limit: pagination.pageSize,
    output_type: "video",
    order: tableSorter.field,
    order_dir: tableSorter.order,
    q,
    from: range ? range[0]?.toDate().getTime() : undefined,
    to: range ? range[1]?.toDate().getTime() : undefined,
    favorites,
    neuronet_id: neronet ? neronet.split('_')[0] : undefined,
    option_id: (neronet && neronet.split('_').length===2)?neronet.split('_')[1]:undefined
  });
  useEffect(() => {
    setPagination({ ...pagination, pageSize: showFull ? 10 : 5 });
  }, [showFull]);
  useEffect(() => {
    if (data) {
      setPagination({ ...pagination, total: data[1] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onclick = (chat: any)=>()=>{
    navigate(`/lk/video/vizard/${chat.vizard.id}`)
  }

  const columns: ColumnsType = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
      sorter: true,
    },
    {
      title: "Запрос",
      dataIndex: "text",
      key: "text",
      render: (text: string, record: any) => {
        return <div style={{cursor: 'pointer'}} onClick={onclick(record)}>{record.name}</div> 
      },
    },
    {
      title: "Тип задачи",
      dataIndex: "neuronet",
      key: "neuronet",
      render: (text: string, record: any) => {
        if (record.option) {
          return (
            <div className="historyTextGenerationsTableNeuronet">
              {record.option.image && (
                <img src={record.option.image.url} width="18px" alt="" />
              )}
              {record.option.name}
            </div>
          );
        } else if (record.neuronet) {
          return (
            <div className="historyTextGenerationsTableNeuronet">
              {record.neuronet.image && (
                <img src={record.neuronet.image.url} width="18px" alt="" />
              )}
              {record.neuronet.name}
            </div>
          );
        }
      },
    },
    {
      title: "Дата",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      render: (text: string, record: any) => {
        return (
          <>
            <span>
              {new Date(record.created_at).toLocaleDateString()}
            </span>
            <span style={{ marginLeft: '8px' }}>
              {new Date(record.created_at).toLocaleTimeString()}
            </span>
          </>
        );
      }
    },
    {
      title: "",
      dataIndex: "operation",
      render: (text: string, record: any) => {
        return (
          <div className="historyTextGenerationsTableOperations">
            <span onClick={toggleFavorite(record)}>
              {record.is_favorite ? <IconStarSelected /> : <IconStar />}
            </span>
            <span>
              <Popconfirm
                style={{ width: "200px" }}
                title="Удалить генерацию? Вы уверены?"
                placement="topRight"
                onConfirm={deleteChat(record)}
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}>
                <div>
                  <IconCross />
                </div>
              </Popconfirm>
            </span>
          </div>
        );
      },
    },
  ];

  const changedTable = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) => {
    setPagination(pagination);
    if (sorter.field) {
      setSorter({ ...tableSorter, field: sorter.field, order: sorter.order });
    }
  };

  const toggleFavorite = (item: any) => () => {
    toggleFavoriteMutation({ chat_id: item.id });
  };

  const deleteChat = (item: any) => () => {
    deleteChatMutation({ chat_id: item.id });
  };
  const setDateRange = (e: any) => {
    setRange(e);
  };
  if (!data) return <Loader />;

  return (
    <div className={"historyTextGenerations " + (showFull ? "showFull" : "")}>
      {!showFull && (
      <div className="historyTextGenerationsHeader">
        <span>Работа с видео</span>
        <span>
            <Button disabled={!isLoading && data && data[1]===0} onClick={() => navigate("/lk/history/video")}>
              Смотреть все генерации
            </Button>
        </span>
      </div>
      )}
      {showFull && (
        <div className={"historyTextGenerationsFilters "+currentAlgorithm}>
          <div className="historyTextGenerationsFiltersSelector">
            <span>Задача</span>
            <span>
              <NeuronetsSelector
                value={neronet}
                onChange={(value) => {
                  setNeronet(value);
                }}
              />
            </span>
          </div>
          <div className="historyTextGenerationsFiltersDatePicker">
            <span>Период</span>
            <span>
              <RangePicker value={range} onChange={setDateRange} />
            </span>
          </div>
          <div className="historyTextGenerationsFiltersCheckboxes">
            <Switch checked={favorites}  onChange={()=>setFavorites(!favorites)}/>
            <span>
              Избранное
            </span>
          </div>
        </div>
      )}
      {!isLoading && data && data[1]>0 && <>
        <Table
          bordered={false}
          columns={columns}
          dataSource={data[0]}
          loading={isLoading}
          onChange={changedTable}
          pagination={pagination}
          // sortDirections={}
          className={
            "historyTextGenerationsTable " +
            (currentAlgorithm === "dark" ? "table_dark" : "")
          }
          rowKey={(record) => record.id}></Table>
        </>
        } 
        { !isLoading && data && data[1]===0 &&
          <Empty />
        }
    </div>
  );
};
