import { AutoComplete } from "antd";
import type { AutoCompleteProps } from 'antd';

import React, { useEffect, useState } from "react";
import { useGetUsersQuery } from "../../store/userApiSlice";
import { User } from "../../types/User.type";
import { DataSourceItemObject } from "antd/es/auto-complete";
import { DefaultOptionType } from "antd/es/select";

type PropsType = {
    onChange: (user: any)=>void,
    value: string
}

type OptionType = {
    label: string,
    value: any
}

const UserSelector: React.FC<PropsType> = ({value, onChange}) => {
    const [q, setSearchQuery] = useState<string>();

    const [pagination, setPagination] = useState<any>({total:1, pageSize: 20, pageSizeOptions:[10,20,50,100], current: 1})
    const { data, error, isLoading, refetch } = useGetUsersQuery({
    page: pagination.current,
    limit: pagination.pageSize,
    q: q
    });
    const [options, setOptions] = useState<Array<OptionType>>([]);

    
    useEffect(()=>{
        if(!data) return
        const optionsData:Array<OptionType> = data[0].map((user: any)=>{
            let firstname = '', lastname = ''
            if(user.profile?.first_name) firstname = user.profile?.first_name
            if(user.profile?.last_name) lastname = user.profile?.last_name
            return {
                value: user.id,
                label: firstname+' '+lastname
            }
        })
        setOptions(optionsData)

    }, [data])


    const [valueLocal, setValue] = useState<string>(value);


    const onSelect = (data: any) => {
        const selectedValue = options.find(u=>u.value === data)
        if(selectedValue){
            setValue(selectedValue?.label)
            onChange(selectedValue.value)
        }
    };

    const onChangeSearchString = (data: string) => {
        setValue(data);
        setSearchQuery(data)
    };

    const onClear = ()=>{
        setValue('')
        onChange(undefined)
    }

    return (<>
    <AutoComplete
        options={options}
        defaultValue={value}
        value={valueLocal}
        allowClear
        onClear={onClear}
        style={{ width: 200 }}
        onSelect={onSelect}
        onSearch={setSearchQuery}
        onChange={onChangeSearchString}
        placeholder="Введите имя пользователя"
      />
    </>)
}

export default UserSelector