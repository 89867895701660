import { Button, Card, Collapse, Flex, Form, Select, Slider } from "antd";
import React, { useEffect, useRef, useState } from "react";

import "./AudioSynthesisProperties.scss";
import { useGetAudioPreviewMutation } from "../../../store/webchatApiSlice";
import { useAppDispatch } from "../../../store/store";
import { showNotification } from "../../../store/notificationSlice";
import { errorDecode } from "../../../api/errorDecode";

const availProps = {
  messages: {
    "ru-ru":
      "Привет!\nЯ Яндекс Спичк+ит.\nЯ могу превратить любой текст в речь.\nТеперь и в+ы - можете!",
    "kk-kk":
      "Привет!\nЯ Яндекс Спичк+ит.\nЯ могу превратить любой текст в речь.\nТеперь и в+ы - можете!",
    "kk-kz":
      "Привет!\nЯ Яндекс Спичк+ит.\nЯ могу превратить любой текст в речь.\nТеперь и в+ы - можете!",
    "uz-uz":
      "Assalomu alaykum!\nMen Yandex SpeechKit.\nMen istalgan matnni nutqqa o‘gira olaman.\nVa endi siz ham buni bajara olasiz!",
    "en-us":
      "Hi there!\nI am Yandex SpeechK+it.\nI can turn any text into speech.\nAnd now you can!",
    "de-de":
      "Hallo!\nIch bin das Yandex SpeechKit.\nIch kann jeden Text in Sprache umwandeln.\nUnd Sie können das jetzt auch!",
    "he-il":
      "שלום לך!\nאני יאנדקס דיבורזה.\nאני יכול להפוך כל טקסט לדיבור.\nועכשיו גם אתה יכול!",
  },
  voicesEmotions: {
    marina: [
      {
        value: "neutral",
        title: "Нейтральный",
      },
      {
        value: "whisper",
        title: "Шёпот",
      },
      {
        value: "friendly",
        title: "Дружелюбный",
      },
    ],
    dasha: [
      {
        value: "neutral",
        title: "Нейтральный",
      },
      {
        value: "good",
        title: "Радостный",
      },
      {
        value: "friendly",
        title: "Дружелюбный",
      },
    ],
    lera: [
      {
        value: "neutral",
        title: "Нейтральный",
      },
      {
        value: "friendly",
        title: "Дружелюбный",
      },
    ],
    masha: [
      {
        value: "good",
        title: "Радостный",
      },
      {
        value: "strict",
        title: "Строгий",
      },
      {
        value: "friendly",
        title: "Дружелюбный",
      },
    ],
    julia: [
      {
        value: "neutral",
        title: "Нейтральный",
      },
      {
        value: "strict",
        title: "Строгий",
      },
    ],
    alexander: [
      {
        value: "neutral",
        title: "Нейтральный",
      },
      {
        value: "good",
        title: "Радостный",
      },
    ],
    anton: [
      {
        value: "neutral",
        title: "Нейтральный",
      },
      {
        value: "good",
        title: "Радостный",
      },
    ],
    kirill: [
      {
        value: "neutral",
        title: "Нейтральный",
      },
      {
        value: "strict",
        title: "Строгий",
      },
      {
        value: "good",
        title: "Радостный",
      },
    ],
    jane: [
      {
        value: "neutral",
        title: "Нейтральный",
      },
      {
        value: "good",
        title: "Радостный",
      },
      {
        value: "evil",
        title: "Раздражённый",
      },
    ],
    omazh: [
      {
        value: "neutral",
        title: "Нейтральный",
      },
      {
        value: "evil",
        title: "Раздражённый",
      },
    ],
    filipp: [],
    ermil: [
      {
        value: "neutral",
        title: "Нейтральный",
      },
      {
        value: "good",
        title: "Радостный",
      },
    ],
    zahar: [
      {
        value: "neutral",
        title: "Нейтральный",
      },
      {
        value: "good",
        title: "Радостный",
      },
    ],
    madirus: [],
    madi: [],
    amira: [],
    nigora: [],
    john: [],
    lea: [],
    naomi: [
      {
        value: "modern",
        title: "Современный",
      },
      {
        value: "classic",
        title: "Классический",
      },
    ],
  },
  voicesLanguages: {
    marina: [
      {
        value: "ru-ru",
        title: "Русский",
      },
      {
        value: "ru-ru",
        title: "Русский",
      },
      {
        value: "ru-ru",
        title: "Русский",
      },
    ],
    dasha: [
      {
        value: "ru-ru",
        title: "Русский",
      },
      {
        value: "ru-ru",
        title: "Русский",
      },
      {
        value: "ru-ru",
        title: "Русский",
      },
    ],
    lera: [
      {
        value: "ru-ru",
        title: "Русский",
      },
      {
        value: "ru-ru",
        title: "Русский",
      },
    ],
    masha: [
      {
        value: "ru-ru",
        title: "Русский",
      },
      {
        value: "ru-ru",
        title: "Русский",
      },
      {
        value: "ru-ru",
        title: "Русский",
      },
    ],
    julia: [
      {
        value: "ru-ru",
        title: "Русский",
      },
      {
        value: "ru-ru",
        title: "Русский",
      },
    ],
    alexander: [
      {
        value: "ru-ru",
        title: "Русский",
      },
      {
        value: "ru-ru",
        title: "Русский",
      },
    ],
    anton: [
      {
        value: "ru-ru",
        title: "Русский",
      },
      {
        value: "ru-ru",
        title: "Русский",
      },
    ],
    kirill: [
      {
        value: "ru-ru",
        title: "Русский",
      },
      {
        value: "ru-ru",
        title: "Русский",
      },
      {
        value: "ru-ru",
        title: "Русский",
      },
    ],
    jane: [
      {
        value: "ru-ru",
        title: "Русский",
      },
      {
        value: "ru-ru",
        title: "Русский",
      },
      {
        value: "ru-ru",
        title: "Русский",
      },
    ],
    omazh: [
      {
        value: "ru-ru",
        title: "Русский",
      },
      {
        value: "ru-ru",
        title: "Русский",
      },
    ],
    filipp: [
      {
        value: "ru-ru",
        title: "Русский",
      },
    ],
    ermil: [
      {
        value: "ru-ru",
        title: "Русский",
      },
      {
        value: "ru-ru",
        title: "Русский",
      },
    ],
    zahar: [
      {
        value: "ru-ru",
        title: "Русский",
      },
      {
        value: "ru-ru",
        title: "Русский",
      },
    ],
    madirus: [
      {
        value: "ru-ru",
        title: "Русский",
      },
    ],
    madi: [
      {
        value: "kk-kz",
        title: "Казахский",
      },
    ],
    amira: [
      {
        value: "kk-kz",
        title: "Казахский",
      },
    ],
    nigora: [
      {
        value: "uz-uz",
        title: "Узбекский",
      },
    ],
    john: [
      {
        value: "en-us",
        title: "Английский",
      },
    ],
    lea: [
      {
        value: "de-de",
        title: "Немецкий",
      },
    ],
    naomi: [
      {
        value: "he-il",
        title: "Иврит",
      },
      {
        value: "he-il",
        title: "Иврит",
      },
    ],
  },
  languagesVoices: {
    "ru-ru": [
      {
        value: "marina",
        title: "Марина",
      },
      {
        value: "dasha",
        title: "Даша",
      },
      {
        value: "lera",
        title: "Лера",
      },
      {
        value: "masha",
        title: "Маша",
      },
      {
        value: "julia",
        title: "Юлия",
      },
      {
        value: "alexander",
        title: "Александр",
      },
      {
        value: "anton",
        title: "Антон",
      },
      {
        value: "kirill",
        title: "Кирилл",
      },
      {
        value: "jane",
        title: "Джейн",
      },
      {
        value: "omazh",
        title: "Омаж",
      },
      {
        value: "filipp",
        title: "Филипп",
      },
      {
        value: "ermil",
        title: "Ермил",
      },
      {
        value: "zahar",
        title: "Захар",
      },
      {
        value: "madirus",
        title: "Мадирус",
      },
    ],
    "kk-kz": [
      {
        value: "madi",
        title: "Мади",
      },
      {
        value: "amira",
        title: "Амира",
      },
    ],
    "uz-uz": [
      {
        value: "nigora",
        title: "Нигора",
      },
    ],
    "en-us": [
      {
        value: "john",
        title: "Джон",
      },
    ],
    "de-de": [
      {
        value: "lea",
        title: "Леа",
      },
    ],
    "he-il": [
      {
        value: "naomi",
        title: "Наоми",
      },
    ],
  },
  langItems: [
    {
      title: "Русский",
      value: "ru-ru",
    },
    {
      title: "Казахский",
      value: "kk-kz",
    },
    {
      title: "Узбекский",
      value: "uz-uz",
    },
    {
      title: "Английский",
      value: "en-us",
    },
    {
      title: "Немецкий",
      value: "de-de",
    },
    {
      title: "Иврит",
      value: "he-il",
    },
  ],
  emotionItems: [
    {
      title: "Нейтральный",
      value: "neutral",
    },
    {
      title: "Шёпот",
      value: "whisper",
    },
    {
      title: "Дружелюбный",
      value: "friendly",
    },
    {
      title: "Радостный",
      value: "good",
    },
    {
      title: "Строгий",
      value: "strict",
    },
    {
      title: "Раздражённый",
      value: "evil",
    },
    {
      title: "Современный",
      value: "modern",
    },
    {
      title: "Классический",
      value: "classic",
    },
  ],
  voiceItems: [
    {
      title: "Марина",
      value: "marina",
      languages: ["ru-ru", "ru-ru", "ru-ru"],
      emotions: ["neutral", "whisper", "friendly"],
    },
    {
      title: "Даша",
      value: "dasha",
      languages: ["ru-ru", "ru-ru", "ru-ru"],
      emotions: ["neutral", "good", "friendly"],
    },
    {
      title: "Лера",
      value: "lera",
      languages: ["ru-ru", "ru-ru"],
      emotions: ["neutral", "friendly"],
    },
    {
      title: "Маша",
      value: "masha",
      languages: ["ru-ru", "ru-ru", "ru-ru"],
      emotions: ["good", "strict", "friendly"],
    },
    {
      title: "Юлия",
      value: "julia",
      languages: ["ru-ru", "ru-ru"],
      emotions: ["neutral", "strict"],
    },
    {
      title: "Александр",
      value: "alexander",
      languages: ["ru-ru", "ru-ru"],
      emotions: ["neutral", "good"],
    },
    {
      title: "Антон",
      value: "anton",
      languages: ["ru-ru", "ru-ru"],
      emotions: ["neutral", "good"],
    },
    {
      title: "Кирилл",
      value: "kirill",
      languages: ["ru-ru", "ru-ru", "ru-ru"],
      emotions: ["neutral", "strict", "good"],
    },
    {
      title: "Джейн",
      value: "jane",
      languages: ["ru-ru", "ru-ru", "ru-ru"],
      emotions: ["neutral", "good", "evil"],
    },
    {
      title: "Омаж",
      value: "omazh",
      languages: ["ru-ru", "ru-ru"],
      emotions: ["neutral", "evil"],
    },
    {
      title: "Филипп",
      value: "filipp",
      languages: ["ru-ru"],
      emotions: [],
    },
    {
      title: "Ермил",
      value: "ermil",
      languages: ["ru-ru", "ru-ru"],
      emotions: ["neutral", "good"],
    },
    {
      title: "Захар",
      value: "zahar",
      languages: ["ru-ru", "ru-ru"],
      emotions: ["neutral", "good"],
    },
    {
      title: "Мадирус",
      value: "madirus",
      languages: ["ru-ru"],
      emotions: [],
    },
    {
      title: "Мади",
      value: "madi",
      languages: ["kk-kz"],
      emotions: [],
    },
    {
      title: "Амира",
      value: "amira",
      languages: ["kk-kz"],
      emotions: [],
    },
    {
      title: "Нигора",
      value: "nigora",
      languages: ["uz-uz"],
      emotions: [],
    },
    {
      title: "Джон",
      value: "john",
      languages: ["en-us"],
      emotions: [],
    },
    {
      title: "Леа",
      value: "lea",
      languages: ["de-de"],
      emotions: [],
    },
    {
      title: "Наоми",
      value: "naomi",
      languages: ["he-il", "he-il"],
      emotions: ["modern", "classic"],
    },
  ],
  textareaAlignment: {
    "he-il": "right",
  },
};

type PropsType = {
  extprops: any,
  setExtProps: (data: any)=>void
}
export const AudioSynthesisProperties: React.FC<PropsType> = ({extprops, setExtProps}) => {
  const dispatch = useAppDispatch()
  const playAudioRef = useRef<any>();
  const [playState, setPlayState] = useState<string>('idle')
  const [getAudioPreview] = useGetAudioPreviewMutation()

  const [hideEmotions, setHideEmotions] = useState<boolean>(false)

  const [voiceOptions, setVoiceOptions] = useState<any[]>([]);

  const [emotionsOptions, setEmotionOptions] = useState<any[]>([]);

  useEffect(() => {
    const voices = availProps.voiceItems.filter((item) => {
      return item.languages.includes(extprops.language);
    });
    setVoiceOptions(voices);

    const emotions = availProps.emotionItems.filter((emotion) => {
      return availProps.voiceItems
        .find((voice) => voice.value === extprops.voice)
        ?.emotions.includes(emotion.value);
    });

    setEmotionOptions(emotions);
  }, [extprops]);

  const setLanguage = (value: string, option: any) => {
    let extPropsNew = Object.assign({}, extprops);
    extPropsNew.language = option.value;
    let voice = availProps.voiceItems
      .filter((item) => {
        return item.languages.includes(value);
      })
      .find((i) => true);
    let default_voice = voice?.value;
    if (default_voice) {
      extPropsNew.voice = default_voice;
    }

    if (voice?.emotions && voice?.emotions.length > 0) {
      let default_emotion = voice?.emotions[0];
      if (default_emotion) {
        extPropsNew.role = default_emotion;
        setHideEmotions(false);
      }
    } else {
      extPropsNew.role = null;
      setHideEmotions(true);
    }

    setExtProps(extPropsNew);
  };

  const setVoice = (value: string) => {
    let extPropsNew = Object.assign({}, extprops);
    let voice = availProps.voiceItems.find((i) => i.value===value);

    if (voice?.emotions && voice?.emotions.length > 0) {
      let default_emotion = voice?.emotions[0];
      if (default_emotion) {
        extPropsNew.role = default_emotion;
        setHideEmotions(false);
      }
    } else {
      extPropsNew.role = null;
      setHideEmotions(true);
    }
    setExtProps({ ...extPropsNew, voice: value });
  };
  const setEmotion = (value: string) => {
    setExtProps({ ...extprops, role: value });
  };
  const setSpeed = (value: number) => {
    setExtProps({ ...extprops, speed: value });
  };
  const setPitch = (value: number) => {
    setExtProps({ ...extprops, pitch: value });
  };

  const playPreview = ()=>{
    getAudioPreview({
        language: extprops.language,
        voice: extprops.voice,
        role: extprops.role,
      }).then((e: any)=>{
      if(e.error){
        const description = errorDecode(e.error)
        dispatch(showNotification(({type: 'error', description, message: 'Ошибка'})))
        return
      }
      playAudioRef.current.src = e.data.audio.url;
      playAudioRef.current.play()
    })
  }

  return (
    <>
      <Card className='audioSynthesisPropertiesCollapse' title={<div className="audioSynthesisPropertiesTitle">Расширенные настройки</div>}>
        <Flex className="audioSynthesisProperties">
          <Flex className="audioSynthesisPropertiesForm">
            <Flex vertical className="audioSynthesisPropertiesFormItem">
              <span>Язык речи</span>
              <span>
                <Select value={extprops.language} onChange={setLanguage}>
                  {availProps.langItems.map((item: any) => {
                    return (
                      <Select.Option key={item.value} value={item.value}>
                        {item.title}
                      </Select.Option>
                    );
                  })}
                </Select>
              </span>
            </Flex>
            <Flex vertical className="audioSynthesisPropertiesFormItem">
              <span>Голос</span>
              <span>
                <Select value={extprops.voice} onChange={setVoice}>
                  {voiceOptions.map((item: any) => {
                    return (
                      <Select.Option key={item.value} value={item.value}>
                        {item.title}
                      </Select.Option>
                    );
                  })}
                </Select>
              </span>
            </Flex>
            <Flex vertical className="audioSynthesisPropertiesFormItem">
              <>
                {!hideEmotions && (
                  <>
                    <span>Амплуа</span>
                    <span>
                      <Select
                        value={extprops.role}
                        onChange={setEmotion}>
                        {emotionsOptions.map((item: any) => {
                          return (
                            <Select.Option
                              key={item.value}
                              value={item.value}>
                              {item.title}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </span>
                  </>
                )}
                {hideEmotions && <span></span>}
              </>
            </Flex>
          </Flex>
          <Flex className="audioSynthesisPropertiesForm">
            <Flex vertical className="audioSynthesisPropertiesFormItem">
              <span>Тембр</span>
              <span>
                <Slider
                  marks={{'-1000': '-1000', 1000: '1000'}}
                  // included={false}
                  defaultValue={0}
                  min={-1000}
                  max={1000}
                  step={250}
                  value={extprops.pitch}
                  onChange={setPitch}
                />
              </span>
            </Flex>
            <Flex vertical className="audioSynthesisPropertiesFormItem">
              <span>Скорость воспроизведения</span>
              <span>
                <Slider
                  marks={{0.1: '0.1',3: '3'}}
                  defaultValue={1}
                  value={extprops.speed}
                  min={0.1}
                  max={3}
                  step={0.1}
                  // included={false}
                  onChange={setSpeed}
                />
              </span>
            </Flex>
            <Flex vertical className="audioSynthesisPropertiesFormItem">
              <span>Учитывается голос и амплуа</span>
              <span>
                <Button disabled={playState==='play'} 
                  onClick={playPreview} >Прослушать</Button>
              </span>
              <audio ref={playAudioRef} onPlay={()=>setPlayState('play')} onEnded={()=>setPlayState('idle')}/>
            </Flex>
          </Flex>
        </Flex>
      </Card>


    </>
  );
};
