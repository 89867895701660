import React, { CSSProperties } from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

type PropsType = {
    className?: string
}

export const IconMicrophone: React.FC<PropsType> = ({className}) => {
    let currentAlgorithm = useSelector(
        (state: RootState) => state.designMode.currentAlgorithm
    );

    let classNames: Array<string> = ['customIcon', currentAlgorithm]
    if(className) classNames.push(className)
    
      return <svg className={classNames.join(' ')} width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.82047 4.31935C8.59297 4.31935 8.41214 4.50018 8.41214 4.72768V5.64935C8.41214 7.71435 6.73214 9.39435 4.66714 9.39435C2.60214 9.39435 0.922135 7.71435 0.922135 5.64935V4.72185C0.922135 4.49435 0.741302 4.31352 0.513802 4.31352C0.286302 4.31352 0.105469 4.49435 0.105469 4.72185V5.64352C0.105469 8.01768 1.9313 9.97185 4.2588 10.1818V11.4243C4.2588 11.6518 4.43964 11.8327 4.66714 11.8327C4.89464 11.8327 5.07547 11.6518 5.07547 11.4243V10.1818C7.39714 9.97768 9.2288 8.01768 9.2288 5.64352V4.72185C9.22297 4.50018 9.04214 4.31935 8.82047 4.31935Z" fill="#7479F6"/>
            <path d="M4.66714 0.166016C3.2438 0.166016 2.0888 1.32102 2.0888 2.74435V5.73102C2.0888 7.15435 3.2438 8.30935 4.66714 8.30935C6.09047 8.30935 7.24547 7.15435 7.24547 5.73102V2.74435C7.24547 1.32102 6.09047 0.166016 4.66714 0.166016ZM5.4313 4.22018C5.39047 4.37185 5.2563 4.47102 5.10464 4.47102C5.07547 4.47102 5.0463 4.46518 5.01714 4.45935C4.78964 4.39518 4.55047 4.39518 4.32297 4.45935C4.1363 4.51185 3.95547 4.40102 3.9088 4.22018C3.8563 4.03935 3.96714 3.85268 4.14797 3.80602C4.49214 3.71268 4.8538 3.71268 5.19797 3.80602C5.37297 3.85268 5.47797 4.03935 5.4313 4.22018ZM5.74047 3.08852C5.68797 3.22852 5.55964 3.31018 5.41964 3.31018C5.3788 3.31018 5.3438 3.30435 5.30297 3.29268C4.89464 3.14102 4.43964 3.14102 4.0313 3.29268C3.8563 3.35685 3.65797 3.26352 3.5938 3.08852C3.52964 2.91352 3.62297 2.71518 3.79797 2.65685C4.35797 2.45268 4.9763 2.45268 5.5363 2.65685C5.7113 2.72102 5.80464 2.91352 5.74047 3.08852Z" fill="#7479F6"/>
            </svg>

}

