import React from "react";

type PropsType = {
  url: string
  width?: string
  height?: string
}

export const GoogleDrivePlayer: React.FC<PropsType> = ({ url, width, height }) => {

  // <iframe width="100%" height="100%" src="https://drive.google.com/file/d/1pdvh_mEnkXPKA3jivYIpYKTaoukxH5p3Yg/preview"></iframe>
  //"https://drive.google.com/file/d/16YsTovvwJJKm1znoFDpnz_qSeimnBIC0/view?usp=drive_link"

  const driveUrl = new URL(url)
  const pathArr = driveUrl.pathname.split('/')
  
  driveUrl.pathname = pathArr.map(e=>{
    if(e==='view') return 'preview'
    if(e==='edit') return 'preview'
    return e
  }).join('/')

  // eslint-disable-next-line jsx-a11y/iframe-has-title
  return <div className="video-responsive" style={{width: width ? width : "400px", height: height ? height: "225px"}}>
    {driveUrl.toString()}
  <iframe
    width={width ? width : "400px"}
    height={height ? height : "225px"}
    src={driveUrl.toString()}
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    title="Embedded youtube"
  />
</div>
}