import React from "react"
import { ImageNoGenerations } from "../Icons/ImageNoGenerations"
import { Flex } from "antd"

type PropsType = {
    title?: string
    subtitle?: string
}



export const Empty: React.FC<PropsType> = ({title, subtitle})=>{

    return <Flex vertical style={{width: '100%', display: 'flex', alignItems: 'center', gap: '24px' }}> 
    <ImageNoGenerations />
    <span style={{
        fontFamily: 'Montserrat',
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: '21.94px',
        textAlign: 'center',
        color: 'var(--Text-text-black, #121724)'

        }}>{title ? title : 'У вас еще нет генераций'}</span>
        <span style={{
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '21px',
        textAlign: 'center',
        color: 'var(--Text-text-placeholder, #979AA4)'}}>
        {subtitle ? subtitle : 'Начните работу с сервисом и получите свои первые результаты'}
        </span>
    </Flex>
}