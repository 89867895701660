import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

const audioPlayerSlice = createSlice({
    name: 'audioPlayer',
    initialState: {
          state: 'idle',
          playingFileId: null,
  },
  reducers: {
    setPlayState: (state, action)=>{
      state.state = 'play'
      state.playingFileId = action.payload
    },
    setPlayStop: (state, action)=>{
      state.state = 'idle'
      state.playingFileId = null
    }
  }
})

export const { setPlayState, setPlayStop} = audioPlayerSlice.actions
export default audioPlayerSlice.reducer


export const getPlayState = (state: RootState, id: any) => {
  if (state.audioPlayer.playingFileId === id){
    return 'play'
  }
  return 'idle'
}