import React, { useState } from 'react';
import { Tabs } from 'antd';


import UserLogs from './UserLogs';
import GenerationsLog from './GenerationsLog';
import { useLocation, useNavigate } from 'react-router-dom';

const Logs: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  
  return (
    <>
      <Tabs 
      onChange={(e)=>{
        navigate(e)
      }}
        activeKey={location.pathname}
        items={[
          {
            key: '/admin/logs/users',
            label: 'Логи пользователей',
            children: <UserLogs />
          },
          {
            key: '/admin/logs/generations',
            label: 'Логи генераций',
            children: <GenerationsLog />
          }
        ]}
      >
      </Tabs>
    </>
  )
}

export default Logs;