import React from "react";

type PropsType = {
  url: string,
  width?: string,
  height?: string
}

export const YouTubePlayer: React.FC<PropsType> = ({ url, width, height }) => {

  const youtubeUrl = new URL(url)
  const embedId = youtubeUrl.searchParams.get('v')
  // eslint-disable-next-line jsx-a11y/iframe-has-title
  return <div className="video-responsive" style={{width: width ? width : "853", height: height ? height: "480"}}>
  <iframe
    width={width ? width : "853"}
    height={height ? height: "480"}
    src={`https://www.youtube.com/embed/${embedId}`}
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    title="Embedded youtube"
  />
</div>
}