import React, { MutableRefObject, useEffect, useRef } from 'react'
import markdownit, { StateCore } from 'markdown-it'
import hljs from 'highlight.js' // https://highlightjs.org/
import 'highlight.js/styles/default.css'
import './MarkdownRenderer.scss'
import MarkdownIt from 'markdown-it'
import { useLocation, useNavigate } from 'react-router-dom'
import { removeListener } from 'process'
type PropsType = {
  text: string
  onLinkClick?: (e: any) => void
}

export const MarkdownRenderer: React.FC<PropsType> = ({text}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const mdContainerRef = useRef<any>()
  
  useEffect(()=>{
    if(!mdContainerRef.current) return
    let elements_a = mdContainerRef.current.querySelectorAll('a')
    for(const a of elements_a) {
      console.log('addListener')
      a.addEventListener('click', onClick)
    }

    return ()=>{
      if(!mdContainerRef.current) return
      let elements_a = mdContainerRef.current.querySelectorAll('a')
      for(const a of elements_a) {
        console.log('removeListener')
        a.removeEventListener('click', onClick)
      }
    }
  }, [text])

  const onClick = (e: any)=>{
    if(e.target.href.startsWith(window.location.origin)){
      
      navigate(e.target.pathname)
      e.preventDefault()
      e.stopPropagation()
    } else {
      const a=document.createElement('a')
      a.href = e.target.href
      a.target = '_blank'
      a.click()
      e.preventDefault()
      e.stopPropagation()
    }
  }
  
const md = markdownit({
  html: true,
  linkify: true,
  typographer: true,
  breaks: true,
  highlight: function (str, lang): string {
    if (lang && hljs.getLanguage(lang)) {
      try {
        return '<pre><code class="hljs">' +
               hljs.highlight(str, { language: lang, ignoreIllegals: true }).value +
               '</code></pre>';
      } catch (__) {}
    }

    return '<pre><code class="hljs">' + md.utils.escapeHtml(str) + '</code></pre>';
  }
})





return <div ref={mdContainerRef} className='markdownRenderer' dangerouslySetInnerHTML={{__html:md.render(text)}} ></div>

}
