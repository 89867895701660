
import React from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

type PropsType = {
  className?: string
}

export const IconFolderAdd: React.FC<PropsType> = ({className})=>{
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
  );

  let classNames: Array<string> = ['customIcon', currentAlgorithm]
  if(className) classNames.push(className)

return <svg className={classNames.join(' ')} width="72" height="73" viewBox="0 0 72 73" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M36.2013 32.3333C37.582 32.3333 38.7013 33.4526 38.7013 34.8333V51.5C38.7013 52.8807 37.582 54 36.2013 54C34.8206 54 33.7013 52.8807 33.7013 51.5V34.8333C33.7013 33.4526 34.8206 32.3333 36.2013 32.3333Z" fill="#DBDBE0" fill-opacity="0.5"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M25.168 43.1666C25.168 41.7859 26.2873 40.6666 27.668 40.6666H44.3346C45.7154 40.6666 46.8346 41.7859 46.8346 43.1666C46.8346 44.5473 45.7154 45.6666 44.3346 45.6666H27.668C26.2873 45.6666 25.168 44.5473 25.168 43.1666Z" fill="#DBDBE0" fill-opacity="0.5"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.76907 8.26773C6.06409 9.97271 5.16797 13.1488 5.16797 19.8333V53.1666C5.16797 59.8512 6.06409 63.0272 7.76907 64.7322C9.47405 66.4372 12.6501 67.3333 19.3346 67.3333H52.668C59.3525 67.3333 62.5286 66.4372 64.2335 64.7322C65.9385 63.0272 66.8346 59.8512 66.8346 53.1666V33.1666C66.8346 26.4821 65.9385 23.306 64.2335 21.6011C62.5286 19.8961 59.3525 19 52.668 19H42.668C40.7757 19 39.2403 18.7212 37.9332 17.9312C36.7662 17.2259 36.0064 16.2213 35.4487 15.4839C35.4131 15.4368 35.3783 15.3908 35.3442 15.346L35.3346 15.3333L30.3346 8.66663C29.325 7.3204 28.855 6.74732 28.2097 6.35967C27.6236 6.00755 26.6182 5.66663 24.3346 5.66663H19.3346C12.6501 5.66663 9.47405 6.56275 7.76907 8.26773ZM4.23354 4.73219C7.52855 1.43717 12.6858 0.666626 19.3346 0.666626H24.3346C27.0511 0.666626 29.0957 1.05904 30.7845 2.07359C32.3477 3.01264 33.3592 4.36378 34.2249 5.52011C34.2617 5.56932 34.2983 5.61817 34.3346 5.66663L39.33 12.3272C40.0261 13.2428 40.245 13.4862 40.5195 13.6521C40.729 13.7787 41.2269 14 42.668 14H52.668C59.3168 14 64.4741 14.7705 67.7691 18.0655C71.0641 21.3605 71.8346 26.5178 71.8346 33.1666V53.1666C71.8346 59.8154 71.0641 64.9727 67.7691 68.2677C64.4741 71.5627 59.3168 72.3333 52.668 72.3333H19.3346C12.6858 72.3333 7.52855 71.5627 4.23354 68.2677C0.938517 64.9727 0.167969 59.8154 0.167969 53.1666V19.8333C0.167969 13.1845 0.938517 8.02721 4.23354 4.73219Z" fill="#DBDBE0" fill-opacity="0.5"/>
  </svg>
}
