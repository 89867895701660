import React, { SyntheticEvent, useRef, useState } from "react";
import { SoundRecorder } from "../../../Components/SoundRecorder/SoundRecorder";
import { AudioRecognitionHistoryList } from "./AudioRecognitionHistoryList";
import { Button, Card, Flex, Input, Select } from "antd";

import './AudioRecognitionFile.scss'
import { IconMicrophone } from "../../../Components/Icons/IconMicrophone";
import { IconDownload } from "../../../Components/Icons/IconDownload";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { apiSlice } from "../../../api/apiSlice";

export const AudioRecognitionFile: React.FC = ()=>{
  const dispatch = useAppDispatch();
  // const [ language, setLanguage ] = useState<string>('ru-ru')
  const [state, setState] = useState<string>('idle')
  const [file, setFile] = useState<any>()
  const token = useAppSelector((state) => state.auth.token);
  const fileInputRef = useRef<any>();

  const uploadFile = ()=>{
    const formData = new FormData();
    formData.append("file", file);
    // formData.append("type", fileType || "mp3");
    // formData.append("language", language);
    fetch("/api/webchat/stt", {
      method: "POST",
      headers: {
        authorization: `bearer ${token}`,
      },
      cache: "no-cache",
      body: formData,
    })
      .then((e) => {
        console.log(e);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        dispatch(apiSlice.util.invalidateTags(["Chats"]));
        setFile(undefined)
      });

  }

  const onFileInput = (e: any)=>{
    setFile(e.target.files.item(0))
  }


  return <Flex vertical style={{width:"100%", gap: '24px'}}>
    <Flex vertical className="audioRecognitionFile">
      <Flex className="audioRecognitionFileLabel">
      Выберете язык, загрузите файл в формате LPCM, OggOpus, MP3 и нажмите кнопку «Распознать»
      </Flex>
      <Flex className="audioRecognitionFileProperties" style={{width: '100%'}}>
        {/* <Select
        defaultValue={'ru-ru'} 
        value={language}
        onChange={(value)=>{setLanguage(value)}}
        style={{width: '300px'}}>
          <Select.Option value='ru-ru'>Русский</Select.Option>
        </Select> */}
        <input ref={fileInputRef} hidden type="file" name="file" accept="audio/mp3, audio/opus, audio/ogg" onInput={onFileInput}></input>
        <Button type="primary" 
        icon={<IconDownload className="primary"/>}
        onClick={()=>{fileInputRef.current.click()}}>
          {state==='idle' && 'Загрузить файл'}
          </Button>
      </Flex>
    </Flex>
    {file && <Card className="audioRecognitionFileCard">
      <Flex style={{width: '100%', justifyContent: 'space-between'}}>
        <span className="fileNameCard">
          {file.name}          
        </span>
        <span>
          <Button onClick={uploadFile} type="primary">Распознать</Button>
        </span>
      </Flex>
      </Card>
    }
  </Flex>
}
