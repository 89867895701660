import React from "react"
import { Select } from "antd"

const languageText: {[key: string]: any} = {
  ar: 'Арабский',
  nl: 'Голландский',
  en: 'Английский',
  fr: 'Французский',
  de: 'Немецкий',
  hi: 'Хинди',
  id: 'Индонезийский',
  it: 'Итальянский',
  ja: 'Японский',
  ko: 'Корейский',
  zh: 'Мандарин (Китайский диалект)',
  pt: 'Португальский',
  ru: 'Русский',
  es: 'Испанский',
  tr: 'Турецкий',
  uk: 'Украинский',
  vi: 'Вьетнамский'
}

type PropsType = {
  value: string,
  onChange: (val: string)=>void
}

export const VizardLanguageSelector: React.FC<PropsType> = ({value, onChange}) => {

  return <>
  <Select value={value} onChange={(value)=>onChange(value)}>
    {Object.keys(languageText).map(key=>{

      return <Select.Option key={key}>{languageText[key]}</Select.Option>

    })}
  </Select>
  </>
}