import React from "react"
import { Checkbox, Select } from "antd"

type PropsType = {
  value: number,
  onChange: (val: number)=>void
}

export const VizardSubtitleSelector: React.FC<PropsType> = ({value, onChange}) => {

  return <>
  <Select value={value} onChange={(e)=>onChange(e)}>
    <Select.Option value={1}>Да</Select.Option>
    <Select.Option value={0}>Нет</Select.Option>
  </Select>
  </>
}