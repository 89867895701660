import React from "react";
import {
  NeuronetAccountKey,
  NeuronetAccountKeyFolders,
  NeuronetAccountKeySecret,
} from "../../../../types/SaveNeuronet.type";
import { Input } from "antd";
import FormItem from "antd/es/form/FormItem";

type PropsType = {
  value: NeuronetAccountKeyFolders;
  onChange: (val: NeuronetAccountKeyFolders) => void;
};

export const AccountKeyFolders: React.FC<PropsType> = ({ value, onChange }) => {
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };

  return (
    <>
      <FormItem
        {...formItemLayout}
        label={<span>Ключ&nbsp;</span>}
        hasFeedback>
        <Input value={value.key} onChange={(val)=>{onChange({...value, key: val.target.value})}} />
      </FormItem>
      <FormItem
        {...formItemLayout}
        label={<span>Folder STT&nbsp;</span>}
        hasFeedback>
        <Input value={value.folder_stt} onChange={(val)=>{onChange({...value, folder_stt: val.target.value})}}/>
      </FormItem>
      <FormItem
        {...formItemLayout}
        label={<span>Folder TTS&nbsp;</span>}
        hasFeedback>
        <Input value={value.folder_tts} onChange={(val)=>{onChange({...value, folder_tts: val.target.value})}}/>
      </FormItem>
    </>
  );
};
