import React, { useState } from "react";

import "./ImageViewer.scss";
import { Modal } from "antd";

type PropsType = {
  url: string;
  width?: string
  alt?: string,
  open?: boolean,
  close?: ()=>void
};

export const ImageViewer: React.FC<PropsType> = ({ url, width, alt, open, close }) => {
  const [show, setShow] = useState<any>(false);

  const openFull = () => {
    setShow(true);
  };
  const closeFull = () => {
    setShow(false);
    if(close) close()
  };
  return (
    <div className="imagePreview">
      <div className='imagePreviewImage' onClick={openFull} style={{backgroundImage: `url('${url}')`}}>
        {/* <img src={url} width={width || "320px"} alt={alt||''}/> */}
      </div>
        <Modal
          getContainer={()=>{return document.getElementById('modals_container')||document.body}}  
          width={'60vw'}
          open={(show||open)}  
          footer={null}
          onOk={() => closeFull()}
          onCancel={() => closeFull()}>
          <div className="fullImage" onClick={closeFull}>
            <img src={url} style={{maxHeight: '70vh', maxWidth: '100%'}} />
          </div>
        </Modal>
    </div>
  );
};
