import { Button, Flex } from "antd";
import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { IconPlay } from "../Icons/IconPlay";
import { IconPause } from "../Icons/IconPause";
import { IconStop } from "../Icons/IconStop";

import "./SoundRecorder.scss";
import { apiSlice } from "../../api/apiSlice";
import { IconMicrophone } from "../Icons/IconMicrophone";
import { IconCross } from "../Icons/IconCross";

type PropsType = {
  state: string;
  // language: string;
  setState: (val: string) => void;
};

enum StatusEnum {
  IDLE = 'idle',
  RECORDING = 'recording',
  PLAYING = 'playing',
  PAUSED = 'paused'
}

export const SoundRecorder: React.FC<PropsType> = ({
  state,
  setState,
  // language,
}) => {
  const dispatch = useAppDispatch();
  const [visibility, setVisibility] = React.useState<boolean>(false);

  const [status, setStatus] = React.useState<StatusEnum>(StatusEnum.IDLE);
  const [recorderState, setRecorderState] = React.useState<string>('idle');
  const [currentTime, setCurrentTime] = React.useState<number>(0);
  const [duration, setDuration] = React.useState<number>(0);
  const durationRef = React.useRef<number>(0);
  const startTimeRef = React.useRef<number>(0);
  const [noMediaDevices, setNoMediaDevices] = React.useState(false);
  // const [playAudioSrc, setPlayAudioSrc] = React.useState<string>()
  const token = useAppSelector((state) => state.auth.token);

  const WIDTH = 636;
  const HEIGHT = 138;

  const recordAudioRef = createRef<HTMLAudioElement>();
  const playAudioRef = useRef<any>();
  const peakAnimation = useRef<any>();
  const durationTimer = useRef<any>();
  const canvas = useRef<any>();
  let mediaRecorder = useRef<MediaRecorder>();
  let analyzerRecorder: AnalyserNode,
    arrayRecorder: any,
    contextRecorder: AudioContext,
    sourceRecorder: MediaStreamAudioSourceNode | MediaElementAudioSourceNode;

  let analyzerPlayer = useRef<any>();
  let contextPlayer = useRef<any>();
  let sourceNode = useRef<any>();
  let dataArray = useRef<any>([]);

  useEffect(() => {
    if (state === "record") {
      setVisibility(true)
      animationStop()
      // setStatus(StatusEnum.RECORDING)
      // startRecord();
    } else {
      // animationStop();
      // mediaRecorder.current && mediaRecorder.current.stop();
    }
  }, [state]);

  const startRecord = () => {
    if(status!=='idle') return
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      setNoMediaDevices(true);
      return;
    }
    navigator.mediaDevices
      .getUserMedia(
        // Установим ограничение на получение только аудио потока
        {
          audio: true,
        }
      )
      // Функция успешного получения потока
      .then((mediaStreamObj) => {
        if (recordAudioRef.current && "srcObject" in recordAudioRef.current) {
          recordAudioRef.current.srcObject = mediaStreamObj;
        }

        // const startTime = new Date().getTime();
        startTimeRef.current = new Date().getTime();
        durationRef.current = 0;
        setDuration(60000)
        setStatus(StatusEnum.RECORDING);
        setRecorderState(StatusEnum.RECORDING)
        setNoMediaDevices(false)
        mediaRecorder.current = new MediaRecorder(mediaStreamObj);
        contextRecorder = new AudioContext();
        sourceRecorder =
          contextRecorder.createMediaStreamSource(mediaStreamObj);
        analyzerRecorder = contextRecorder.createAnalyser();
        analyzerRecorder.fftSize = 128;
        sourceRecorder.connect(analyzerRecorder);
        arrayRecorder = new Uint8Array(analyzerRecorder.frequencyBinCount); //new Uint8Array(analyzerRecorder.fftSize);
        
        mediaRecorder.current.onstart = onMediarecorderStart.bind(this) 
        mediaRecorder.current.onstop = onMediarecorderStop.bind(this);
        mediaRecorder.current.onpause = onMediarecorderPause.bind(this)
        mediaRecorder.current.onresume = onMediarecorderResume.bind(this)

        mediaRecorder.current.start();
        
        mediaRecorder.current.ondataavailable = function (ev) {
          dataArray.current.push(ev.data);
        };
        
      })
      .catch(function (err) {
        setNoMediaDevices(true);
        console.log("The following getUserMedia error occured: " + err);
      });
  };

  

  const onMediaRecord = () => {
    let durationTime = (new Date().getTime() - startTimeRef.current) + durationRef.current;
    console.log('onMediaRecord', durationTime, startTimeRef.current , durationRef.current)
    startTimeRef.current = new Date().getTime()
    durationRef.current = durationTime
    setCurrentTime(durationTime)
    // if (durationTime > 60000) {
    //   mediaRecorder.current?.stop();
    // }
  }

  const getDurationStr = (duration: number): string => {
    const durationTime = new Date(duration)
    let d = "";
    if(durationTime.getUTCHours()>0){
      d +=
        durationTime.getUTCHours() >= 10
          ? durationTime.getUTCHours() + ":"
          : "0" + durationTime.getUTCHours() + ":";
    }
    d +=
      durationTime.getMinutes() >= 10
        ? durationTime.getMinutes() + ":"
        : "0" + durationTime.getMinutes() + ":";
    d +=
      durationTime.getSeconds() >= 10
        ? durationTime.getSeconds()
        : "0" + durationTime.getSeconds();
      return d
  }

  const onMediarecorderStart = () => {
    peakAnimation.current = requestAnimationFrame(animate);
    durationTimer.current = setInterval(onMediaRecord, 200);

  }

  const onMediarecorderStop = () => {
    // blob of type mp3
    clearInterval(durationTimer.current);
    let audioData = new Blob(dataArray.current, { type: "audio/mp3" });
    dataArray.current = [];
    if (playAudioRef.current) {
      let audioSrc = window.URL.createObjectURL(audioData);
      playAudioRef.current.src = audioSrc;
    }
    // Pass the audio url to the 2nd video tag
    const tracks = mediaRecorder.current?.stream.getTracks();
    tracks?.forEach((track) => {
      track.stop();
    });
    setState('canplay')
    cancelAnimationFrame(peakAnimation.current);
    animationStop();
    setStatus(StatusEnum.IDLE);
    setRecorderState(StatusEnum.IDLE)
    setDuration(currentTime)
    setCurrentTime(0)
    uploadBlob(audioData, "mp3");
  };

  const onMediarecorderPause = () => {
    cancelAnimationFrame(peakAnimation.current);
    clearInterval(durationTimer.current);

    setRecorderState(StatusEnum.PAUSED)
  }

  const onMediarecorderResume = () => {
    durationTimer.current = setInterval(onMediaRecord.bind(this), 200);
    peakAnimation.current = requestAnimationFrame(animate);
    setRecorderState(StatusEnum.RECORDING)
  }
  const uploadBlob = (audioBlob: any, fileType: any) => {
    const formData = new FormData();
    formData.append("file", audioBlob, `file.${fileType || "mp3"}`);
    formData.append("type", fileType || "mp3");
    // formData.append("language", language);
    fetch("/api/webchat/stt", {
      method: "POST",
      headers: {
        authorization: `bearer ${token}`,
      },
      cache: "no-cache",
      body: formData,
    })
      .then((e) => {
        console.log(e);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        dispatch(apiSlice.util.invalidateTags(["Chats"]));
      });

    return;
  };

  const getPeakLevel = (): { level: number; fft: any[] } => {
    if (!analyzerRecorder)
      return {
        level: 0,
        fft: Array(64).fill(0),
      };
    analyzerRecorder.getByteTimeDomainData(arrayRecorder);
    const level =
      arrayRecorder.reduce(
        (max: number, current: number) =>
          Math.max(max, Math.abs(current - 127)),
        0
      ) / 128;
    analyzerRecorder.getByteFrequencyData(arrayRecorder);
    return {
      level: level * 1000,
      fft: arrayRecorder,
    };
  };

  const animate = (time: number) => {
    let data = getPeakLevel();
    var canvasCtx = canvas.current?.getContext("2d");
    if (!canvasCtx) return;
    const bufferLength = analyzerRecorder.frequencyBinCount;
    canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);

    const barWidth = WIDTH / bufferLength - 1;
    let barHeight;
    let x = 0;

    for (let i = 0; i < bufferLength; i++) {
      barHeight = data.fft[i] / 4;
      canvasCtx.fillStyle = `#7479F6`;
      canvasCtx.strokeStyle = `#7479F6`;
      canvasCtx.beginPath();
      canvasCtx.roundRect(
        x,
        HEIGHT / 2 - barHeight - 5,
        barWidth,
        barHeight * 2 + 5,
        8
      );
      canvasCtx.stroke();
      canvasCtx.fill();
      x += barWidth + 2;
    }

    peakAnimation.current = requestAnimationFrame(animate);
  };

  const animationStop = () => {
    var canvasCtx = canvas.current?.getContext("2d");
    if (!canvasCtx) return;
    const bufferLength = 64;
    canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);

    const barWidth = WIDTH / bufferLength - 1;
    let barHeight;
    let x = 0;

    for (let i = 0; i < bufferLength; i++) {
      barHeight = 0;
      canvasCtx.fillStyle = `#7479F6`;
      canvasCtx.strokeStyle = `#7479F6`;
      canvasCtx.beginPath();
      canvasCtx.roundRect(
        x,
        HEIGHT / 2 - barHeight - 5,
        barWidth,
        barHeight * 2 + 5,
        8
      );
      canvasCtx.stroke();
      canvasCtx.fill();
      x += barWidth + 2;
    }
  };

  // const playAudioSrcStop = (e: any)=>{
  //   cancelAnimationFrame(peakAnimation.current);
  // }

  const playAudio = () => {
    if (status === StatusEnum.IDLE) {
      setStatus(StatusEnum.PLAYING);
      if (playAudioRef.current) {
        contextPlayer.current = new AudioContext();
        if (!sourceNode.current) {
          sourceNode.current = contextPlayer.current.createMediaElementSource(
            playAudioRef.current
          );
          analyzerPlayer.current = contextPlayer.current.createAnalyser();
          analyzerPlayer.current.fftSize = 128;
          sourceNode.current.connect(contextPlayer.current.destination);
          sourceNode.current.connect(analyzerPlayer.current);
        }
        
        arrayRecorder = new Uint8Array(
          analyzerPlayer.current.frequencyBinCount
        );

        peakAnimation.current = requestAnimationFrame(animatePlayer);
        playAudioRef.current.ontimeupdate = ()=>{
          setCurrentTime(playAudioRef.current.currentTime*1000)
        }
        playAudioRef.current.addEventListener('ended', stopAudio);
        playAudioRef.current.play();
      }
    }
    if(status===StatusEnum.PAUSED){
      arrayRecorder = new Uint8Array(
        analyzerPlayer.current.frequencyBinCount
      );
      setStatus(StatusEnum.PLAYING);
      playAudioRef.current.play();
    }
  };
  const pauseAudio = () => {
    if (status === StatusEnum.PLAYING) {
      if (playAudioRef.current) {
        playAudioRef.current.pause();
      }
      setStatus(StatusEnum.PAUSED);
    }
  };
  const stopAudio = () => {
      setStatus(StatusEnum.IDLE);
      if (playAudioRef.current) {
        cancelAnimationFrame(peakAnimation.current);
        animationStop();
        setCurrentTime(0)
        playAudioRef.current?.pause();
        playAudioRef.current.currentTime = 0;
      }
  }

  const stopRecord = () => {
    mediaRecorder.current?.stop()
  }
  const pauseRecord = () => {
    mediaRecorder.current?.pause()
  }
  const resumeRecord = () => {
    if (mediaRecorder.current?.state==='paused'){
      mediaRecorder.current?.resume()
    } else {
      startRecord()
    } 
  }

  const hideSoundRecorder = ()=>{
    if(status===StatusEnum.PLAYING){
      stopAudio()
    }
    if(status===StatusEnum.RECORDING){
      stopRecord()
    }
    setVisibility(false)
  }

  const getPeakLevelPlayer = (): { level: number; fft: any[] } => {
    if (!analyzerPlayer.current)
      return {
        level: 0,
        fft: Array(64).fill(0),
      };
    analyzerPlayer.current.getByteTimeDomainData(arrayRecorder);
    const level =
      arrayRecorder.reduce(
        (max: number, current: number) =>
          Math.max(max, Math.abs(current - 127)),
        0
      ) / 128;
    analyzerPlayer.current.getByteFrequencyData(arrayRecorder);
    return {
      level: level * 1000,
      fft: arrayRecorder,
    };
  };

  const animatePlayer = (time: number) => {
    let data = getPeakLevelPlayer();
    var canvasCtx = canvas.current?.getContext("2d");
    if (!canvasCtx) return;
    const bufferLength = analyzerPlayer.current.frequencyBinCount;
    canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);

    const barWidth = WIDTH / bufferLength - 1;
    let barHeight;
    let x = 0;

    for (let i = 0; i < bufferLength; i++) {
      barHeight = data.fft[i] / 4;
      canvasCtx.fillStyle = `#7479F6`;
      canvasCtx.strokeStyle = `#7479F6`;
      canvasCtx.beginPath();
      canvasCtx.roundRect(
        x,
        HEIGHT / 2 - barHeight - 5,
        barWidth,
        barHeight * 2 + 5,
        8
      );
      canvasCtx.stroke();
      canvasCtx.fill();
      x += barWidth + 2;
    }

    peakAnimation.current = requestAnimationFrame(animatePlayer);
  };

  return (
    <>
      <Flex className={"soundRecorder "+(visibility?"visible":'hidden')}>
        <Flex style={{justifyContent: 'space-between', width:'100%'}}>
        <div style={{width: '20%'}}>
          {status==='recording' && <>
            Идет запись
            {recordAudioRef.current?.duration}
          </>
          }
          {status==='playing' && <>
            Проигрывание
            {recordAudioRef.current?.duration}
          </>
            }
          {noMediaDevices && <div>Не найден микрофон</div>}
        </div>
        <Flex  style={{width: '20%', justifyContent: 'center'}} className="soundRecorderTime">{getDurationStr(currentTime)} / {getDurationStr(duration)}</Flex>
        <div  style={{width: '20%',display: 'flex', justifyContent: 'flex-end'}} onClick={hideSoundRecorder} >
          <IconCross /> 
        </div>
        </Flex>

        <audio ref={recordAudioRef}></audio>
        <audio ref={playAudioRef}></audio>

        <Flex
          vertical
          style={{
            height: "138px",
            width: "636px",
            alignItems: "flex-end",
            alignSelf: "center",
          }}
          className="soundRecordVisualisation">
          <canvas ref={canvas} width={626} height={138}></canvas>
        </Flex>
        <Flex style={{gap:'8px'}}>
          {state==='record' && <>
            <Button disabled={recorderState===StatusEnum.RECORDING} onClick={resumeRecord}>
              <IconMicrophone />
            </Button>
            <Button disabled={recorderState!==StatusEnum.RECORDING} onClick={pauseRecord}>
              <IconPause />
            </Button>
            <Button onClick={stopRecord}>
              <IconStop />
            </Button>
          </>}
          {state==='canplay' && <>
            <Button disabled={status===StatusEnum.PLAYING} onClick={playAudio}>
              <IconPlay />
            </Button>
            <Button disabled={status!==StatusEnum.PLAYING} onClick={pauseAudio}>
              <IconPause />
            </Button>
            <Button disabled={status!==StatusEnum.PLAYING} onClick={stopAudio}>
              <IconStop />
            </Button>
          </>}
        </Flex>
      </Flex>
    </>
  );
};
