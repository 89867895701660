import {
  Button,
  Card,
  Flex,
  GetProp,
  Input,
  message,
  Progress,
  Upload,
} from "antd";
import FormItem from "antd/es/form/FormItem";
import React, { memo, useState } from "react";
import { useCreateProjectMutation } from "../../../store/vizardApiSlice";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { VizardLanguageSelector } from "./VizardLanguageSelector";
import { VizardFormatSelector } from "./VizardFormatSelector";
import { VizardTimeSelector } from "./VizardTimeSelector";
import { VizardSubtitleSelector } from "./VizardSubtitleSelector";
import { VizardHighlightSubtitleSelector } from "./VizardHighlightSubtitleSelector";
import { UploadOutlined } from "@ant-design/icons";
import { useAppSelector } from "../../../store/store";
import { UploadProps } from "antd/lib";

import "./LKVideoVizardCreateProject.scss";
import { YouTubePlayer } from "../../../Components/VideoPlayer/YoutubePlayer";
import { GoogleDrivePlayer } from "../../../Components/VideoPlayer/GoogleDrivePlayer";
import { VimeoPlayer } from "../../../Components/VideoPlayer/VimeoPlayer";
import { VideoType } from "../../../types/Video.type";
import { IconFolderAdd } from "../../../Components/Icons/IconFolderAdd";
import { VideoPlayer } from "../../../Components/VideoPlayer/VideoPlayer";

type VizardCreateType = {
  url?: string;
  video?: VideoType;
  language: string;
  format: string;
  time: number;
  subtitle: number;
  highlights: number;
};
type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

export const LKVideoVizardCreateProject: React.FC = () => {
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  };
  const navigate = useNavigate();
  const location = useLocation();
  const token = useAppSelector((state) => state.auth.token);
  const [loading, setLoading] = useState<boolean>(false);
  const [percent, setPercent] = useState<number>(0);
  const [uploadStatus, setUploadStatus] = useState<"normal" | "active" | "success" | "exception" | undefined>('normal');


  const [vizardData, setVizardData] = useState<VizardCreateType>({
    url: "",
    language: "ru",
    format: "MP4",
    time: 0,
    subtitle: 0,
    highlights: 0,
  });
  const [createProject] = useCreateProjectMutation();

  const startProject = () => {
    createProject(vizardData)
      .then((e: any) => {
        console.log(e);
        if (e.data.vizardProject) {
          navigate(`${location.pathname}/${e.data.vizardProject.id}`);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleChange: UploadProps["onChange"] = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      setUploadStatus('active')
      setPercent(info.event?.percent ? Math.round(info.event?.percent) : 0);
      console.log(info);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      const video = info.file.response;
      setVizardData({ ...vizardData, video: video });
      setLoading(false);
      setPercent(0);
      setUploadStatus('success')
    } else {
      setLoading(false);
      setPercent(0);
      setUploadStatus('exception')
    }
  };

  const beforeUpload = (file: FileType) => {
    const isJpgOrPng =
      file.type === "video/mp4" || file.type === "video/x-msvideo";
    if (!isJpgOrPng) {
      message.error("You can only upload MP4/AVI file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 1000;
    if (!isLt2M) {
      message.error("Video file must be smaller than 1GB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const getVideoType = (videourl: string): number => {
    const url = new URL(videourl);
    const origin = url.origin;

    switch (origin) {
      case "https://drive.google.com":
        return 3;
      case "https://www.youtube.com":
      case "https://youtu.be":
        return 2;
      case "https://vimeo.com":
        return 4;
      case "https://streamyard.com":
        return 5;
      default:
        return 1;
    }
  };

  const uploadButton = (
    <Flex className="fileUploadArea">
      <Flex className="fileUploadAreaIcon">
        <IconFolderAdd />
      </Flex>
      <Flex vertical className="fileUploadAreaText">
        {!loading && !vizardData.video && uploadStatus==='normal' && <div>
          <div><span className="btn">Нажмите, чтобы загрузить файл</span> или перетащите файл в это окно.</div>
          <div>Формат: MP4, MOV, 3GP, AVI.</div>
          <div>Максимальный размер: 8 Гб,</div>
          <div>Длительность: не более 2 часов</div>
        </div>
        }
        {loading && uploadStatus==='active' && 
        <div className="progressBarContainer"> 
          <Progress size={"default"} type="circle" percent={percent} status={uploadStatus} />
        </div>
        }
        {vizardData.video && uploadStatus==='success' && 
          <div className="progressBarContainer"> 
          <Progress size={"default"} type="circle" percent={100} status={uploadStatus} />
          {vizardData.video.meta.filename}
        </div>}
        {vizardData.video && uploadStatus==='exception' && 
          <div className="progressBarContainer"> 
          <Progress size={"default"} type="circle" percent={100} status={uploadStatus} />
        </div>}
      </Flex>
    </Flex>
  );
  {/* <Button type="primary" icon={<UploadOutlined />}>
    {loading && <Progress size={"small"} percent={percent} />}
    {!loading && "Загрузить"}
  </Button> */}

  return (
    <>
    <Flex className="vizardCreateProject" vertical gap={'24px'}>
      <Flex className="vizardCreateProjectCard">
        <Flex className="vizardCreateProjectUrlFile">
          <Flex className="vizardCreateProjectUrl" vertical gap={'24px'}>
            <span>Введите URL видео</span>
            <Input
              disabled={loading || !!vizardData.video}
              value={vizardData.url}
              placeholder="Вставьте ссылку на видео"
              onChange={(e) => {
                setVizardData({ ...vizardData, url: e.target.value });
              }}
            />
            <Flex className="vizardCreateProjectFile">
              <Upload
                disabled={!!vizardData.url}
                name="file"
                action={`/api/webchat/vizard/upload`}
                headers={{ authorization: "bearer " + token }}
                // listType="picture-circle"
                className="vizardCreateProjectFileUploader"
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={handleChange}>
                {uploadButton}
              </Upload>
            </Flex>
          </Flex>
          <Flex className="vizardCreateProjectPreview">
            {vizardData.url && getVideoType(vizardData.url)===2 && <YouTubePlayer width="80%" height="auto" url={vizardData.url} />}
            {vizardData.url && getVideoType(vizardData.url)===3 && <GoogleDrivePlayer width="80%" height="auto" url={vizardData.url} />}
            {vizardData.url && getVideoType(vizardData.url)===4 && <VimeoPlayer width="80%" height="auto" url={vizardData.url} />}
            {vizardData.video && getVideoType(vizardData.video.url)===1 && <VideoPlayer width="80%" height="auto" url={vizardData.video.url} /> }
          </Flex>
        </Flex>
      </Flex>
      <Card className="vizardCreateProjectCard">
        <Flex className="vizardCreateProjectForm" vertical>
          <Flex className="vizardCreateProjectFormLine">
            <Flex className="vizardCreateProjectFormInputContainer">
              <div>Введите язык видео&nbsp;</div>
              <VizardLanguageSelector
                value={vizardData.language}
                onChange={(e) => setVizardData({ ...vizardData, language: e })}
              />
            </Flex>
            <Flex className="vizardCreateProjectFormInputContainer">
              <span>Введите формат видео&nbsp;</span>
              <VizardFormatSelector
                value={vizardData.format}
                onChange={(e) => setVizardData({ ...vizardData, format: e })}
              />
            </Flex>
            <Flex className="vizardCreateProjectFormInputContainer">
              <span>Длительность каждого ролика&nbsp;</span>
              <VizardTimeSelector
                value={vizardData.time}
                onChange={(e) => setVizardData({ ...vizardData, time: e })}
              />
            </Flex>
          </Flex>
          <Flex className="vizardCreateProjectFormLine">
            <Flex className="vizardCreateProjectFormInputContainer">
              <span>Включить субтитры?&nbsp;</span>
              <VizardSubtitleSelector
                value={vizardData.subtitle}
                onChange={(e) => setVizardData({ ...vizardData, subtitle: e })}
              />
            </Flex>
            <Flex className="vizardCreateProjectFormInputContainer">
              <span>Подсвечивать субтитры&nbsp;</span>
              <VizardHighlightSubtitleSelector
                value={vizardData.highlights}
                onChange={(e) =>
                  setVizardData({ ...vizardData, highlights: e })
                }
              />
            </Flex>
            <Flex className="vizardCreateProjectFormInputContainer">
              <Button disabled={loading} onClick={startProject}>
                Создать проект
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Card>
    </Flex>
    </>
  );
};
