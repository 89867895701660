import { DeleteOutlined, FormOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Table, Tooltip } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../store/store";
import { useGetNeuronetOptionsQuery, useSaveNeuronetOptionMutation } from "../../../store/neuronetApiSlice";
import { NeuronetOptionEditor } from "./NeuronetOptionsEditor";
import { SaveNeuronetOption } from "../../../types/SaveNeuronetOption.type";
import { showNotification } from "../../../store/notificationSlice";
import { errorDecode } from "../../../api/errorDecode";


export const NeuronetOptions: React.FC = () => {
  const dispatch = useAppDispatch()
  let currentAlgorithm = useSelector((state: RootState) => state.designMode.currentAlgorithm)
  const [pagination, setPagination] = useState<any>({ total: 1, pageSize: 20, pageSizeOptions: [10, 20, 50, 100], current: 1 })
  const [openModal, setOpenModal] = useState<boolean>();
  const [editedItem, setEditedItem] = useState<any>()
  const {data, isLoading, refetch } = useGetNeuronetOptionsQuery({ 
    page: pagination.current,
    limit: pagination.pageSize
  })
  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Icon",
      dataIndex: "image",
      key: "image",
      render: (text: string, record: any)=>{
        if(record.image){
          return <div><img style={{width: '16px', height: '16px'}} src={record.image?.url} alt=''></img></div>
        }
        return ''
      }
    },
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Нейросеть",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Alias",
      dataIndex: "alias",
      key: "alias",
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      render: (text: string, record: any) => {
        return (
          <div className="operations">
            <span style={{marginRight: '8px'}}>
              <Tooltip title='Редактировать'>
                <FormOutlined onClick={handleOpenEditor(record)} />
              </Tooltip>
            </span>
            <span style={{marginRight: '8px'}}>
              <Popconfirm title="Вы уверены?" onConfirm={() => sureDelete(record)}
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />} >
                <Tooltip title='Удалить пользователя'>
                  <DeleteOutlined color="#990000" />
                </Tooltip>
              </Popconfirm>
            </span>
            {/* <span>
              <InfoCircleOutlined onClick={() => navigate(`/admin/user/${record.id}`)} />
            </span> */}

          </div>
        );
      }
    },
  ];
  const sureDelete = (record: any)=>{
    refetch()
  }
  const handleOpenEditor = (option: SaveNeuronetOption)=>()=>{
    setEditedItem(option)
    setOpenModal(true)

  }
  const changedTable = (e: any) => {
    setPagination(e)
  };
  const closeEditor = () => {
    setOpenModal(false)
    refetch()
  }

  const [saveData, result] = useSaveNeuronetOptionMutation()
  const save = (data: SaveNeuronetOption)=>{
    const {id, name, about_text, type, prompt, image, alias}: SaveNeuronetOption = data
    return saveData({id, name, about_text, type, prompt, image, alias})
    .unwrap()
    .then(() => {return true})
    .catch((error: any) => {
      const description = errorDecode(error)
      dispatch(showNotification({type: 'error',description, message: 'Ошибка сохранения'}))
      return false
    })
  }
  
  if(isLoading){
    return <>Loading...</>
  }

  return <>
    {/* <Button onClick={()=>{handleOpenEditor(null)}}>add</Button> */}
    
    <div className="searchBar">
        <div>
          <span>
          </span>
          <span>
            <Button 
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleOpenEditor({type:"chatgpt", name:'', about_text:'', prompt:'', alias:''})}>
              Добавить опции 
            </Button>
          </span>
        </div>
      </div>
    <Table
        bordered
        columns={columns}
        dataSource={data[0]||[]}
        pagination={pagination}
        loading={isLoading}
        onChange={changedTable}
        className={currentAlgorithm === 'dark' ? 'table_dark' : ""}
        rowKey={record => record.id}></Table>
    {openModal && <NeuronetOptionEditor editedItem={editedItem} save={save} closeModal={closeEditor} modalVisible={openModal} />}
  </>
}