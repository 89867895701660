import React, { useRef, useState } from "react"
import { useParams } from "react-router-dom";
import { getChatByIdAndOptionId, useGetCreateChatsOptionsQuery, useSendMessageMutation } from "../../../store/webchatApiSlice";
import { Button, Card } from "antd";
import { ImageSettingSelector } from "../../../Components/ImageSettingSelector/imageSettingSelect";
import { useAppDispatch } from "../../../store/store";
import { showNotification } from "../../../store/notificationSlice";
import { errorDecode } from "../../../api/errorDecode";
import { LastImageGenerations } from "../dashboard/LastImageGenerations";
import './GenerationImages.scss'
import { LoadingOutlined } from "@ant-design/icons";

export const GenerationImages: React.FC = () => {
    const params = useParams();
    const dispatch = useAppDispatch();
    const inputRef = useRef<HTMLInputElement>(null)
    const { neuronet_id, option_id, chat_id } = params;
    const [sendMessage, { isLoading: savingMessage }] = useSendMessageMutation();
    const { data: createChatData } = useGetCreateChatsOptionsQuery({});
    const chatData = getChatByIdAndOptionId(
        createChatData,
        neuronet_id,
        option_id
    );
    let formDataSettings = {text: ''}

    const settingSelectValue = chatData?.data?.length>0 ? chatData.data.map((el: any) => {
        formDataSettings = { ...formDataSettings, [el.name_prop]: el.default}
        return {
            "title": el.title,
            "values": el.values,
            "default": el.default,
            "onChange": (value: any) => changePayload(el.name_prop, value)
        }
    }): [];
    const [formdata, setFormData]: any = useState(formDataSettings)

    const changePayload = (name: string, value: any) => {
        const newPayload = { ...formdata, [name]: value }
        setFormData(newPayload)
    }

    const saveMessage = () => {
        send()
    }

    const send = () => {
        if (formdata.text.length === 0) {
            return dispatch(
                showNotification({
                    type: "error",
                    description: "Нельзя отправить пустое сообщение",
                    message: "Внимание!",
                })
            );
        }
        let payload: any = { ...formdata };
        if (chat_id) {
            payload = {
                ...payload,
                chat_id: chat_id,
            };
        } else {
            payload = {
                ...payload,
                neuronet_id: Number.parseInt(neuronet_id || "0"),
                option_id: Number.parseInt(option_id || "0"),
                chat_id: 0,
            };
        }
        sendMessage(payload)
            .unwrap()
            .then((e) => {
                setFormData({ ...formdata, text: '' });

            })
            .catch((error: any) => {
                const description = errorDecode(error);
                dispatch(
                    showNotification({
                        type: "error",
                        description,
                        message: "Ошибка сохранения",
                    })
                );
            });
    };

    return <div className="generationImages">
        <div className="generationImagesHeader">
            {chatData?.image &&
                <span className="generationImagesHeaderImage">
                    <img src={chatData?.image?.url} width="32px" height="32px" alt="" />
                </span>
            }
            <span className="generationImagesHeaderText">{chatData?.name}</span>

        </div>
        <div className={"generationImagesMain" + (savingMessage ? " disabled" : "")}>
            <div className="generationImagesMainText" onClick={() => { inputRef?.current?.focus() }}>
                <input
                    placeholder="Опишите идею"
                    ref={inputRef}
                    disabled={savingMessage}
                    name="text" id="text" value={formdata.text}
                    onChange={(value) => changePayload("text", value.target.value)}></input>
                <div className="generationImagesMainTextBtn" onClick={saveMessage}>
                    <Button type="primary" disabled={savingMessage} style={{width: '101px'}}>
                        {!savingMessage && 'Генерация'}
                        {savingMessage && <LoadingOutlined />}
                    </Button>
                </div>
            </div>
            <div>
                <Card 
                title={<div className="generationImagesMainSettingsHeader">Расширенные настройки</div>}
                className="generationImagesMainSettings">
                    <ImageSettingSelector data={settingSelectValue}/>
                </Card>
            </div>
        </div>
        <div className="generationImagesHistory">
            <div className="generationImagesHistoryTitle">Результат</div>
            <div className="generationImagesHistoryBody">
                <LastImageGenerations neuronet_id={neuronet_id} option_id={option_id} chat_id={chat_id} />
            </div>
        </div>
    </div>
}