import { User } from "../../../types/User.type";
import { UserRoleEnum } from "../../../types/UserRole.enum";
import { IconEdit } from "../../Icons/IconEdit";
import { IconGenHistory } from "../../Icons/IconGenHistory";
import { IconLogs } from "../../Icons/IconLogs";
import { IconSpeedtest } from "../../Icons/IconSpeedtest";
import { IconSupport } from "../../Icons/IconSupport";
import { IconUsers } from "../../Icons/IconUsers";

// type MenuItem = Required<MenuProps>['items'][number];


export type MenuItemSrcType = {
  key: string,
  title: string
  path: (string|RegExp)[]
  icon?: any,
  children?: MenuItemSrcType[]
  type?: 'group',
  user?: User,
  roles: UserRoleEnum[]
  show: boolean
}
export const menuItems: MenuItemSrcType[] =[
  {
    key: '/admin/users',
    title:'Пользователи', 
    path: ['/admin/users'],
    icon: IconUsers, 
    roles: [UserRoleEnum.SuperAdmin, UserRoleEnum.Admin],
    show: true
  },
  {
    key: '/admin/logs/users',
    title:'Логи', 
    path: ['/admin/logs/users', '/admin/logs/generations'],
    icon: IconLogs, 
    roles: [UserRoleEnum.SuperAdmin, UserRoleEnum.Admin],
    show: true
  },
  
  // {
  //   title:'Графика', 
  //   path: '/graphics',
  //   icon: 'RadarChartOutlined', 
  //   roles: [UserRoleEnum.SuperAdmin, UserRoleEnum.Admin, UserRoleEnum.Member],
  //   show: true
  // },
  {
    key: '/admin/neuronets/accounts',
    title:'Нейросети', 
    path: ['/admin/neuronets/accounts'],
    icon: IconEdit, 
    roles: [UserRoleEnum.SuperAdmin, UserRoleEnum.Admin],
    show: true
  },
  // {
  //   key: '/admin/chatbots',
  //   title: 'Чат боты', 
  //   path: '/admin/chatbots',
  //   icon: IconEdit, 
  //   roles: [UserRoleEnum.SuperAdmin, UserRoleEnum.Admin],
  //   show: true
  // },
  {
    key: '/admin/history',
    title:'История генераций', 
    path: ['/admin/history'],
    icon: IconGenHistory, 
    roles: [UserRoleEnum.SuperAdmin, UserRoleEnum.Admin],
    show: true
  },
  {
    key: '/admin/support',
    title:'Поддержка', 
    path: ['/admin/support'],
    icon: IconSupport, 
    roles: [UserRoleEnum.SuperAdmin, UserRoleEnum.Admin],
    show: true
  },
  {
    key: '/admin/faq/',
    title:'Справка', 
    path: ['/admin/faq/'],
    icon: IconSupport, 
    roles: [UserRoleEnum.SuperAdmin, UserRoleEnum.Admin],
    show: true
  },
  {
    key: '/admin/statistics/',
    title:'Статистика', 
    path: ['/admin/statistics/'],
    icon: IconSupport, 
    roles: [UserRoleEnum.SuperAdmin, UserRoleEnum.Admin],
    show: true
  }
]