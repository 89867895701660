import { SendOutlined } from "@ant-design/icons";
import { Button, Form } from "antd";
import FormItem from "antd/es/form/FormItem";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RootState, useAppDispatch } from "../../../store/store";
import {
  useSendMessageMutation,
} from "../../../store/webchatApiSlice";
import { showNotification } from "../../../store/notificationSlice";
import { errorDecode } from "../../../api/errorDecode";
import { MessageList } from "./MessageList";
import { useSelector } from "react-redux";

import './ChatbotChat.scss'

export const ChatbotChat: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [send_text, setSendText] = useState<string>("");
  const [sendMessage] = useSendMessageMutation();
  const params = useParams();
  let currentAlgorithm = useSelector((state: RootState) => state.designMode.currentAlgorithm)

  let { neuronet_id, option_id, chat_id } = params;

  const location = useLocation();
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  };
  useEffect(() => {
    window.addEventListener("keyup", onkeyup);
    return () => {
      window.removeEventListener("keyup", onkeyup);
    };
  });

  const onkeyup = (key: KeyboardEvent) => {
    if(key.key==='Enter' && key.shiftKey===false &&  key.ctrlKey===false && key.altKey===false) {
      send();
    }
  };

  const send = () => {
    if (send_text.length === 0) {
      return dispatch(
        showNotification({
          type: "error",
          description: "Нельзя отправить пустое сообщение",
          message: "Внимание!",
        })
      );
    }
    let payload: any = { text: send_text };
    if (!chat_id) {
      payload = {
        ...payload,
        neuronet_id: Number.parseInt(neuronet_id || "0"),
        option_id: Number.parseInt(option_id || "0"),
        chat_id: 0,
      };
    } else {
      payload = {
        ...payload,
        chat_id: chat_id,
      };
    }
    sendMessage(payload)
      .unwrap()
      .then((e) => {
        // console.log(e)
        setSendText("");
        if (!chat_id) {
          navigate(`${location.pathname}/${e.webchat_id}`);
        }
      })
      .catch((error: any) => {
        const description = errorDecode(error);
        dispatch(
          showNotification({
            type: "error",
            description,
            message: "Ошибка сохранения",
          })
        );
      });
  };

  return (
    <div className="chatbotChatPage">
      <div className="messageList">
        {chat_id && <MessageList chat_id={chat_id} />}
      </div>
      <div className="sendMessageContainer">
        <div className="sendMessageForm">
          <Form>
            <FormItem {...formItemLayout} hasFeedback>
              <TextArea
                rows={4}
                value={send_text}
                onChange={(val) => setSendText(val.target.value)}
              />
            </FormItem>
          </Form>
        </div>
        <div className="sendMessageBtn">
          <Button
            type="primary"
            shape="circle"
            size="large"
            icon={<SendOutlined />}
            style={{ width: "100%" }}
            onClick={send}></Button>
        </div>
      </div>
      <div>
        
      </div>

    </div>
  );
};
