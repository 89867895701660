import React, { createRef, useEffect, useState } from "react";

import './SoundPlayer.scss'

type PropsType = {
  url: string,
  state: string,
  onStop: ()=>void
}

export const SoundPlayer: React.FC<PropsType> = ({url, state, onStop})=>{
  const playerRef = createRef<HTMLAudioElement>();
  const [ currentTime, setCurrentTime ] = useState(0)

  useEffect(()=>{
    if(!playerRef.current) return
    if(state === 'play') {
      playerRef.current.addEventListener('timeupdate', timeupdate)
      playerRef.current.addEventListener('ended', playend)
      playerRef.current.play()
    } else {
      if(playerRef.current){
        playerRef.current.pause();
        playerRef.current.currentTime = 0;
      }
    }
    
  }, [state])

  const timeupdate = (e: any) => {
    setCurrentTime(e.target.currentTime/e.target.duration * 100);
  }

  const playend = (e: any) => {
    onStop && onStop()
  }


  return <div className="soundPlayer">
    <audio ref={playerRef} src={url}></audio>
    <div className={"progressBarContainer "+ state}>
      <div className="progressBar" style={{width: `${currentTime}%`, height: '4px', background: 'red'}}></div>
    </div>
  </div>
}