import { apiSlice } from "../api/apiSlice";

export const faqApiSlice = apiSlice
.enhanceEndpoints({addTagTypes:[
  'FaqPages'
]})
.injectEndpoints({
  endpoints: builder => ({
    saveFaq: builder.mutation({
      query: (payload) => ({
        url: '/api/faq',
        method: 'POST',
        body: payload
      }),
      invalidatesTags: ['FaqPages']
    }),
    deleteFaqPage: builder.mutation({
      query: (payload) => ({
        url: `/api/faq/page/${payload.id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['FaqPages']
    }),
    getFaqList: builder.query({
      query: (payload) => ({
        url: '/api/faq/list',
        params: payload,
        method: 'GET',
      }),
      providesTags:['FaqPages']
    }),
    getFaqPage: builder.query({
      query: (page) => ({
        url: `/api/faq/page/${page}`,
        method: 'GET',
      }),
      providesTags:['FaqPages']
    })

  })
})

export const { 
  useGetFaqPageQuery, 
  useGetFaqListQuery,
  useSaveFaqMutation,
  useDeleteFaqPageMutation
} = faqApiSlice