import React, { useEffect } from "react";
import { Tooltip } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuItemSrcType } from "./MenuItems";
import { useGetCurrentUserQuery } from "../../../store/userApiSlice";
import { RootState, useAppDispatch } from "../../../store/store";
import { logout } from "../../../store/authSlice";

import './Menu.scss'

import { useSelector } from "react-redux";
import { getActiveMenuItem, setActiveMenuItem } from "../../../store/layoutSlice";

type PropsType = {
  collapsed: boolean,
  menuItems: MenuItemSrcType[]
}

const AppMenu: React.FC<PropsType> = ({collapsed, menuItems}) => {
  const dispatch = useAppDispatch()
  const location = useLocation();
  const navigate = useNavigate();
  const activeMenuItem = useSelector(getActiveMenuItem)

  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
  );
  const { data: user, error } = useGetCurrentUserQuery({});

  if(error) {
    dispatch(logout())
  }

  useEffect(()=>{
    let currentItem = menuItems.filter(item=>item.show).find(item=>{
      return item.path.find(path=>{
        if(typeof(path)==='string' && path===location.pathname) return true
        if(path.constructor === RegExp && path.test(location.pathname)) return true
        return false
      })     
    }
    )
    if(currentItem) dispatch(setActiveMenuItem({key: currentItem.key}))
  }, [location])
  

  if (!user) return <></>;

  const onClick = (e: MenuItemSrcType) => () => {
    navigate(e.key);
  };

  return (
    <div className={"menu " + currentAlgorithm+(collapsed?' collapsed':'')}>
      {menuItems && menuItems.filter(item=>item.show).map(item=>{
        const selected = item.key === activeMenuItem.key
        return <div key={item.key} className={"menuItem " + (selected?'selected':'') } onClick={onClick(item)}>
          <span className="menuicon">
            <Tooltip title={item.title}>
              {item.icon({className: selected?'selected':undefined})}
              </Tooltip>
          </span>
          <span className="menutitle">
            {item.title}
          </span>
          {selected && <span className="selectedMark"></span>}
        </div>
      })}
    </div>
  );
};

export default AppMenu;
