import { apiSlice } from "../api/apiSlice";

export const statisticsApiSlice = apiSlice
.enhanceEndpoints({addTagTypes:['Statistic']})
.injectEndpoints({
    endpoints: builder => ({
      getStatistics: builder.query({
        query: params=>'/api/statistics/data',
        providesTags: ['Statistic']
      }),
    })
  })

export const {
  useGetStatisticsQuery
} = statisticsApiSlice