import React from "react";

import './SupportRequestCard.scss'
import { SupportRequestItem } from "../../../types/SupportRequestItem.type";
import { Tag } from "antd";
import { SupportStatusEnum } from "../../../types/SupportStatus.enum";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { UserAvatar } from "../../../Components/common/UserAvatar";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { SupportPriorityEnum } from "../../../types/SupportPriority.enum";
import { SupportCategoryEnum } from "../../../types/SupportCategory.enum";

type PropsType = {
  item: SupportRequestItem
}
export const SupportRequestCard: React.FC<PropsType> = ({item}) => {

  const navigate = useNavigate();

  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
  );
  // const [ openDetails, setOpenDetails ] = useState<boolean>(false)

  return <div className={"supportRequestCard " + currentAlgorithm}>
    <div className="supportRequestCardHeader">
      <span className="supportRequestCardHeaderId">
      #{item.id}
      </span>
      <span className="supportRequestCardHeaderId">
      <UserAvatar user={item.user} />
      </span>
      <span className="supportRequestCardHeaderTags">
        
          {item.status===SupportStatusEnum.OPEN && <Tag style={{height: '22px'}} className="statusOpenTag">Открыта</Tag>}
          {item.status===SupportStatusEnum.CLOSED && <Tag style={{height: '22px'}} color="statusClosedTag">Закрыта</Tag>}
          
        {item.category===SupportCategoryEnum.COMMON && <Tag  style={{height: '22px'}}>Общее</Tag>}
        {item.category===SupportCategoryEnum.FEEDBACK && <Tag  style={{height: '22px'}}>Отзывы</Tag>}
        {item.category===SupportCategoryEnum.OTHER && <Tag  style={{height: '22px'}}>Другие</Tag>}
        {item.category===SupportCategoryEnum.Suggestions && <Tag  style={{height: '22px'}}>Предложения</Tag>}
        {item.category===SupportCategoryEnum.TECH && <Tag  style={{height: '22px'}} color="geekblue">Технические отзывы</Tag>}
        
        {item.priority===SupportPriorityEnum.CRITICAL && <Tag  style={{height: '22px'}} color="red">Критический приоритет</Tag>}
        {item.priority===SupportPriorityEnum.HIGH && <Tag  style={{height: '22px'}} color="orange">Высокий приоритет</Tag>}
        {item.priority===SupportPriorityEnum.LOW && <Tag  style={{height: '22px'}}  color="blue">Низкий приоритет</Tag>}
        {item.priority===SupportPriorityEnum.NORMAL && <Tag  style={{height: '22px'}} color="green">Нормальный приоритет</Tag>}
        
      </span>
    </div>
    <div className="supportRequestCardSubject">
    {item.subject}
    </div>
    <div className="supportRequestCardBody">
      <div className="supportRequestCardBodyText">
        {item.messages[0].text}
      </div>  
      <div>
        <ArrowRightOutlined onClick={()=>{navigate(`${item.id}/`)}} />
      </div>
    </div>
    {/* <SupportRequestDetails open={openDetails} item={item} close={()=>{setOpenDetails(false)}} /> */}

  </div>

}
