import React from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

type PropsType = {
    className?: string
}

export const IconAudio: React.FC<PropsType> = ({className}) => {
    let currentAlgorithm = useSelector(
        (state: RootState) => state.designMode.currentAlgorithm
    );

    let classNames: Array<string> = ['customIcon', currentAlgorithm]
    if(className) classNames.push(className)
    
      return <svg className={classNames.join(' ')}  width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.1859 2.02203V8.6137C11.1859 9.7687 10.2467 10.7079 9.09171 10.7079C7.94254 10.7079 6.99754 9.7687 6.99754 8.6137C6.99754 7.46453 7.94254 6.52537 9.09171 6.52537C9.58171 6.52537 10.0192 6.69453 10.375 6.97453V3.5037L5.00254 5.03203V9.73953C5.00254 10.8945 4.05754 11.8337 2.90838 11.8337C1.75338 11.8337 0.814209 10.8945 0.814209 9.73953C0.814209 8.59037 1.75338 7.6512 2.90838 7.6512C3.39254 7.6512 3.83004 7.82037 4.18588 8.09453V2.93787C4.18588 2.08037 4.70504 1.41537 5.52754 1.1937L8.89921 0.272033C9.58171 0.0853661 10.1592 0.149533 10.5675 0.464533C10.9817 0.773699 11.1859 1.2987 11.1859 2.02203Z" fill="#7479F6"/>
</svg>
}

