import React from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

type PropsType = {
    className?: string
}

export const IconVolumeHigh: React.FC<PropsType> = ({className}) => {
    let currentAlgorithm = useSelector(
        (state: RootState) => state.designMode.currentAlgorithm
    );

    let classNames: Array<string> = ['customIcon', currentAlgorithm]
    if(className) classNames.push(className)
    
      return <svg className={classNames.join(' ')} width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.29893 1.2004C8.9767 1.57491 9.31234 2.36437 9.31234 3.32167V8.67667C9.31234 9.63162 8.97638 10.4204 8.29937 10.7955C7.62218 11.1707 6.77524 11.037 5.96501 10.529L4.26657 9.46462C4.14254 9.3919 4.00478 9.35333 3.87567 9.35333H3.0415C2.39073 9.35333 1.8223 9.19034 1.41964 8.78769C1.01699 8.38504 0.854004 7.8166 0.854004 7.16583V4.8325C0.854004 4.18173 1.01699 3.61329 1.41964 3.21064C1.8223 2.80799 2.39073 2.645 3.0415 2.645H3.87567C4.00478 2.645 4.14255 2.60643 4.26658 2.53371L5.96493 1.46933C6.77448 0.96178 7.62139 0.826026 8.29893 1.2004ZM7.87575 1.96626C7.58206 1.80398 7.09017 1.79659 6.42974 2.21067L4.72188 3.281L4.71736 3.28369C4.46911 3.43108 4.17682 3.52 3.87567 3.52H3.0415C2.52561 3.52 2.21904 3.64868 2.03836 3.82936C1.85768 4.01004 1.729 4.3166 1.729 4.8325V7.16583C1.729 7.68173 1.85768 7.98829 2.03836 8.16897C2.21904 8.34966 2.52561 8.47833 3.0415 8.47833H3.87567C4.17682 8.47833 4.46911 8.56725 4.71736 8.71465L4.72188 8.71733L6.42967 9.78762C7.08941 10.2013 7.58127 10.193 7.87531 10.0301C8.16955 9.8671 8.43734 9.45421 8.43734 8.67667V3.32167C8.43734 2.54063 8.16922 2.12842 7.87575 1.96626Z" fill="#2B3858"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.3621 3.31601C10.5553 3.1709 10.8296 3.20989 10.9747 3.4031C12.1299 4.94125 12.1299 7.05708 10.9747 8.59524C10.8296 8.78844 10.5553 8.82743 10.3621 8.68232C10.1689 8.53722 10.1299 8.26296 10.275 8.06976C11.1964 6.84291 11.1964 5.15542 10.275 3.92857C10.1299 3.73537 10.1689 3.46111 10.3621 3.31601Z" fill="#2B3858"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M11.4297 1.85761C11.6229 1.71255 11.8972 1.75161 12.0422 1.94485C13.8449 4.3463 13.8449 7.65203 12.0422 10.0535C11.8972 10.2467 11.6229 10.2858 11.4297 10.1407C11.2365 9.99567 11.1974 9.72143 11.3424 9.52819C12.9115 7.43797 12.9115 4.56036 11.3424 2.47014C11.1974 2.27691 11.2365 2.00266 11.4297 1.85761Z" fill="#2B3858"/>
        </svg>
}