import React from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

type PropsType = {
    className?: string
}

export const IconNotepad: React.FC<PropsType> = ({className}) => {
    let currentAlgorithm = useSelector(
        (state: RootState) => state.designMode.currentAlgorithm
    );

    let classNames: Array<string> = ['customIcon', currentAlgorithm]
    if(className) classNames.push(className)
    
      return <svg className={classNames.join(' ')}  width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.6665 2.33398C8.6665 3.16065 7.99317 3.83398 7.1665 3.83398H4.83317C4.41984 3.83398 4.0465 3.66732 3.77317 3.39398C3.49984 3.12065 3.33317 2.74732 3.33317 2.33398C3.33317 1.50732 4.0065 0.833984 4.83317 0.833984H7.1665C7.57984 0.833984 7.95317 1.00065 8.2265 1.27398C8.49984 1.54732 8.6665 1.92065 8.6665 2.33398Z" fill="#EAEAEC"/>
<path d="M10.5532 2.85398C10.3998 2.72732 10.2265 2.62732 10.0398 2.55398C9.8465 2.48065 9.65317 2.63398 9.61317 2.83398C9.3865 3.97398 8.37984 4.83398 7.1665 4.83398H4.83317C4.1665 4.83398 3.53984 4.57398 3.0665 4.10065C2.71984 3.75398 2.47984 3.31398 2.3865 2.84065C2.3465 2.64065 2.1465 2.48065 1.95317 2.56065C1.17984 2.87398 0.666504 3.58065 0.666504 5.00065V11.5007C0.666504 13.5007 1.85984 14.1673 3.33317 14.1673H8.6665C10.1398 14.1673 11.3332 13.5007 11.3332 11.5007V5.00065C11.3332 3.91398 11.0332 3.24732 10.5532 2.85398ZM3.33317 7.66732H5.99984C6.27317 7.66732 6.49984 7.89398 6.49984 8.16732C6.49984 8.44065 6.27317 8.66732 5.99984 8.66732H3.33317C3.05984 8.66732 2.83317 8.44065 2.83317 8.16732C2.83317 7.89398 3.05984 7.66732 3.33317 7.66732ZM8.6665 11.334H3.33317C3.05984 11.334 2.83317 11.1073 2.83317 10.834C2.83317 10.5607 3.05984 10.334 3.33317 10.334H8.6665C8.93984 10.334 9.1665 10.5607 9.1665 10.834C9.1665 11.1073 8.93984 11.334 8.6665 11.334Z" fill="#EAEAEC"/>
</svg>

}

