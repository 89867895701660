import React, { useState } from "react";
import { ImageViewer } from "../common/ImageViewer";
import { useDeleteChatMutation, useToggleFavoriteMutation } from "../../store/webchatApiSlice";
import { LoadingOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { IconStarSelected } from "../Icons/IconStarSelected";
import { IconStar } from "../Icons/IconStar";
import { IconLinearEye } from "../Icons/IconLinearEye";
import { IconCross } from "../Icons/IconCross";

import './ChatAudioTextCard.scss'
import { Modal, Popconfirm } from "antd";
import { IconDownload } from "../Icons/IconDownload";
import { IconNotepad } from "../Icons/IconNotepad";
import { ChatType } from "../../types/Chat.type";

type PropsType = {
    item: ChatType,
    mode: string
}
export const ChatAudioTextCard: React.FC<PropsType> =({item, mode})=>{
    const [ toggleFavoriteLoading, setTogglingFavoriteLoading ] = useState<boolean>(false)
    const [ deleteChatMutation ] = useDeleteChatMutation()
    const [ toggleFavoriteMutation ] = useToggleFavoriteMutation()
    const [ openFull, setOpenFull ] = useState<ChatType>()

    const saveData = (function () {
      var a = document.createElement("a");
      document.body.appendChild(a);
      
      return function (data: any, fileName:string) {
          var blob = new Blob([data], {type: "octet/stream"}),
              url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(url);
      };
  }());

    const downloadText = (url: any)=>()=>{
        saveData(item.webchat_result_text, item.name+'.txt')
      }
      const deleteChat = ()=>{
        // console.log(item)
        deleteChatMutation({chat_id: item.id})
      }
      const openTextPreview = (item: any)=>()=>{
        setOpenFull(item)
      }
      const toggleFavorite = (item: any)=>()=>{
        setTogglingFavoriteLoading(true)
        toggleFavoriteMutation({chat_id: item.id}).finally(()=>{
          setTogglingFavoriteLoading(false)
        })
      }
    
    return <div key={item.id} className="chatAudioTextCard">
    <div className="chatAudioTextCardTitle">
      <span>
        <IconNotepad />
      </span>
      <span>
        {item.name}
      </span>
      </div>
    <div className="chatAudioTextCardText">
        { item.state==='loading' && <div className="textLoader"><LoadingOutlined style={{ fontSize: '64px', color: '#08c' }} /></div>}
        { item.state==='idle' && <div onDoubleClick={()=>{
          navigator.clipboard.writeText(item.webchat_result_text)
        }}>
          {item.webchat_result_text}
        </div>
        }
    </div>
    <div className="chatAudioTextCardIcons">
      { mode ==='user' && <div className="chatAudioTextCardIconBtn" onClick={toggleFavorite(item)}>
      { toggleFavoriteLoading? <LoadingOutlined /> :
      (item.is_favorite? <IconStarSelected /> : <IconStar />)}
      </div>
      }
      { mode ==='user' &&  <div className="chatAudioTextCardIconBtnGap"></div> }
      <div className="chatAudioTextCardIconBtn" onClick={downloadText(item.webchat_result_image?.url)}><IconDownload /></div>
      <div className="chatAudioTextCardIconBtnGap"></div>
      <div className="chatAudioTextCardIconBtn" onClick={openTextPreview(item)}><IconLinearEye /></div>
      <div className="chatAudioTextCardIconBtnGap"></div>
      <div className="chatAudioTextCardIconBtn">
          <Popconfirm style={{width: '200px'}}
                title="Удалить генерацию? Вы уверены?"
                placement="topRight"
                onConfirm={deleteChat}
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}>
                  <div>
                    <IconCross />
                  </div>
              </Popconfirm>
        </div>
    </div>
    <Modal open={!!openFull}
    title={openFull?.name}
    onCancel={()=>setOpenFull(undefined)}
    footer={null}
    getContainer={()=>{return document.getElementById('modals_container')||document.body}}  

    >
      {openFull?.webchat_result_text}
    </Modal>
  </div>
}