import React, { useEffect, useRef } from "react";
import { useGetChatMessagesListQuery } from "../../store/webchatApiSlice";
import { ChatMessageUser } from "./ChatMessageUser";
import { MessageType } from "../../types/Message.type";
import { useGetCurrentUserQuery } from "../../store/userApiSlice";
import { ChatMessageReply } from "./ChatMessageReply";
import { MessageTypeEnum } from "../../types/MessageType.enum";
import { ChatMessageLoader } from "./ChatMessageLoader";

type PropsType = {
  chat_id: string | undefined
}

export const  ChatMessages: React.FC<PropsType> = ({chat_id})=>{
  const scrollDemoRef = useRef<HTMLDivElement>(null)
  const { data } = useGetChatMessagesListQuery({ chat_id })
  const {data: user} = useGetCurrentUserQuery({})

  useEffect(()=>{
    if (!data || !scrollDemoRef.current) return
      const imgNode = scrollDemoRef.current.querySelectorAll<HTMLElement>('div.message')[data.length-1];
      if(imgNode){
        imgNode?.scrollIntoView()
      }
  }, [data])

  

  return <div 
      id='messageList' 
      ref={scrollDemoRef}
      style={{ display:'flex', flexDirection: 'column', gap: '12px' }}
      >
        {data && data.map((message: MessageType) => {
          if(message.type === MessageTypeEnum.LOADER){
            return <ChatMessageLoader key={message.id} message={message} />
          }
          if(user.id===message.user_id){
            return <ChatMessageUser 
              key={message.id} 
              message={message} />;
          } else {
            return <ChatMessageReply key={message.id} message={message} />;
          }
        })}
        {/* <div id='listEnd' ref={listEndRef}></div> */}
      </div>

  
}