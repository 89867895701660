import React from "react"
import { Select } from "antd"

const timeOptions: {value: number, title: string}[] = [
  {
    value: 0,
    title: 'Авто'
  },
  {
    value: 1,
    title: 'До 30 секунд'
  },
  {
    value: 2,
    title: 'От 30 секунд до 60 секунд'
  },
  {
    value: 3,
    title: 'От 60 секунд до 90 секунд'
  },
  {
    value: 4,
    title: 'от 90 секунд до 3 минут'
  }

]

type PropsType = {
  value: number,
  onChange: (val: number)=>void
}


export const VizardTimeSelector: React.FC<PropsType> = ({value, onChange}) => {

  return <>
  <Select value={value} onChange={(value) => onChange(value)}>
    {timeOptions.map(option=>{

      return <Select.Option key={option.value} value={option.value}>{option.title}</Select.Option>

    })}
  </Select>
  </>
}