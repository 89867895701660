import React from "react";
import { SupportRequestMessage } from "../../../types/SupportRequestItem.type";
import { UserAvatar } from "../../../Components/common/UserAvatar";

type PropsType = {
  item: SupportRequestMessage
}

export const SupportMessage: React.FC<PropsType> = ({item}) => {

  const formatDate = (date: any): string=>{
    return Intl.DateTimeFormat('ru-RU', {
      dateStyle: "short",
      timeStyle: "short"
    }).format(new Date(date))
  }

  return <>
  <div className="supportRequestDetailsBody">
    <div className="supportRequestDetailsBodyUser">
      <UserAvatar user={item.user} />
      <span>
        {formatDate(item.created_at)}
      </span>
    </div>
    <div className="supportRequestDetailsBodyText">{item.text}</div>
    </div>
  </>
}