import React, { useEffect, useRef, useState } from "react";
import { useGetLogsQuery } from "../../../store/logsSlice";
import {
  Input,
  Table,
  DatePicker,
  theme,
  TableColumnsType,
  Button,
  Flex,
} from "antd";
import LogsDetails from "./LogDetails";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import UserSelector from "../../../Components/common/UserSelector";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

import "./GenerationsLog.scss";
import { UserAvatar } from "../../../Components/common/UserAvatar";
import { User } from "../../../types/User.type";
import { useGetGenerationsLogsQuery } from "../../../store/generationsLogsSlice";
import GenerationsLogDetails from "./GenerationsLogDetails";
const { RangePicker } = DatePicker;
interface DataType {
  id: number;
  date: string;
  name: string;
  description: string;
}

const GenerationsLog: React.FC = () => {
  const [range, setRange] = useState<any>();
  const [searchUser, setSearchUser] = useState<number>();
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
  );
  const {
    token: { colorText },
  } = theme.useToken();

  const [activeRecord, setActiveRecord] = useState<any>();
  const [pagination, setPagination] = useState<any>({
    total: 1,
    pageSize: 20,
    pageSizeOptions: [10, 20, 50, 100],
    current: 1,
  });
  const [q, setSearchQuery] = useState<string>();

  const { data, error, isLoading } = useGetGenerationsLogsQuery({
    page: pagination.current,
    limit: pagination.pageSize,
    from: range ? range[0]?.toDate().getTime() : undefined,
    to: range ? range[1]?.toDate().getTime() : undefined,
    user_id: searchUser,
  });

  const downloadExcel = () => {
    const a = document.createElement("a");
    const params: { [key: string]: string | undefined } = {
      user_id: searchUser ? searchUser.toString() : undefined,
      from: range ? range[0]?.toDate().getTime() : undefined,
      to: range ? range[1]?.toDate().getTime() : undefined,
    };
    a.href =
      "/api/generations/excel?" +
      Object.keys(params)
        .filter((key: string) => params[key] !== undefined)
        .map((key: string) => `${key}=${params[key]}`)
        .join("&");
    a.target = "_blank";
    a.click();
  };

  useEffect(() => {
    if (data) {
      setPagination({ ...pagination, total: data[1] });
    }
  }, [data]);

  //   account_id: null
  // alias: "DALL3_FREE_REQUEST"
  // created_at: "2024-12-24T11:54:34.577Z"
  // error: null
  // id: 16
  // neuronet_id: 2
  // option_id: null
  // prompt: "Сгенерируй логотип компании , занимающейся печатью на 3d принтерах и металлообработкой"
  // result: "{\"user_id\":13,\"deleted_at\":null,\"parent_id\":null,\"format\":\"png\",\"width\":1024,\"height\":1024,\"size\":1007461,\"orientation\":null,\"digest\":\"3b307dcc8493ae978b4a6876e3dd3c5360596352\",\"url\":\"https://aibot.parseit.ru/public/images/message_image/message_210.png\",\"geo_coord\":null,\"exif\":{\"ImageWidth\":1024,\"ImageHeight\":1024,\"BitDepth\":8,\"ColorType\":\"RGB\",\"Compression\":\"Deflate/Inflate\",\"Filter\":\"Adaptive\",\"Interlace\":\"Noninterlaced\"},\"meta\":{\"response\":{\"Bucket\":\"public\",\"Key\":\"images/message_image/message_210.png\",\"Location\":\"http://127.0.0.1/public/images/message_image/message_210.png\",\"ETag\":\"564b9382ccbff30fe0b1362eb0f50f9a\"},\"secure_url\":\"https://aibot.parseit.ru/public/images/message_image/message_210.png\",\"url\":\"https://aibot.parseit.ru/public/images/message_image/message_210.png\",\"format\":\"png\",\"original_filename\":\"message_210.png\",\"original_extension\":\"png\",\"height\":1024,\"width\":1024,\"bytes\":1007461,\"created_at\":\"2024-12-24T11:54:52.945Z\",\"bucket\":\"public\"},\"created_at\":\"2024-12-24T11:54:52.802Z\",\"updated_at\":\"2024-12-24T11:54:52.947Z\",\"id\":210}"
  // row_id: 1428
  // row_name: "MessageEntity"
  // status: "success"
  // updated_at: "2024-12-24T11:55:13.862Z"

  const columns: TableColumnsType<DataType> = [
    {
      title: "Дата",
      dataIndex: "created_at",
      key: "created_at",
      filterSearch: true,
      render: (text: string, record: any) => {
        return (
          <>
            <span>{new Date(record.created_at).toLocaleDateString()}</span>
            <span style={{ marginLeft: "8px" }}>
              {new Date(record.created_at).toLocaleTimeString()}
            </span>
          </>
        );
      },
    },
    {
      title: "Пользователь",
      dataIndex: "user_id",
      key: "user_id",
      render: (text: string, record: any) => {
        const user: User = record.user;
        return <UserAvatar user={record.user} />;
      },
    },
    {
      title: "Alias",
      dataIndex: "alias",
      key: "alias",
      render: (text: string) => {
        return <>{text}</>;
      },
    },
    {
      title: "Тип записи",
      dataIndex: "row_name",
      key: "row_name",
    },
    {
      title: "Описание",
      dataIndex: "prompt",
      key: "prompt",
      render: (text: string) => {
        return <span className="promptContainer">{text}</span>;
      },
    },
    {
      title: "Действия",
      dataIndex: "operation",
      render: (text: string, record: any) => {
        return (
          <div className="operations">
            <span>
              <EyeOutlined onClick={showDetails(record)} />
            </span>
          </div>
        );
      },
    },
  ];
  const changedTable = (e: any) => {
    setPagination(e);
  };
  const showDetails = (record: any) => () => {
    setActiveRecord(record);
  };
  const closeDetails = () => {
    setActiveRecord(undefined);
  };

  return (
    <div className="generationsLogContainer">
      <div className="generationsLogSearchBar">
        <div className="generationsLogSearchBarLine">
          <div className="generationsLogSearchBarCell">
            <span>Выберите даты</span>
            <span>
              <RangePicker value={range} onChange={setRange} />
            </span>
          </div>
          <div className="generationsLogSearchBarCell">
            <span>Пользователь</span>
            <span>
              <UserSelector onChange={setSearchUser} value="" />
            </span>
          </div>
          <div className="generationsLogSearchBarCell">
            <Button onClick={downloadExcel}>Получить в формате Excel</Button>
          </div>
        </div>
      </div>
      {!isLoading && data && (
        <Table
          bordered
          columns={columns}
          dataSource={data[0]}
          pagination={pagination}
          loading={isLoading}
          onChange={changedTable}
          rowKey={(record) => record.id}
          className={"generationsLogTable " + currentAlgorithm}
          style={{ color: colorText }}></Table>
      )}
      {activeRecord && (
        <GenerationsLogDetails record={activeRecord} close={closeDetails} />
      )}
    </div>
  );
};

export default GenerationsLog;
