import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

const videoPlayerSlice = createSlice({
    name: 'videoPlayer',
    initialState: {
          open: false,
          url: null,
  },
  reducers: {
    openVideoPlayer: (state, action)=>{
      state.open = true
      state.url = action.payload.url
    },
    closeVideoPlayer: (state, action)=>{
      state.open = false
      state.url = null
    }
  }
})

export const { openVideoPlayer, closeVideoPlayer} = videoPlayerSlice.actions
export default videoPlayerSlice.reducer
