import React, { useEffect, useState } from "react";
import { useGetChatsQuery } from "../../../store/webchatApiSlice";
import { Flex, Pagination } from "antd";
import { ChatAudioTextCard } from "../../../Components/ChatAudioTextCard/ChatAudioTextCard";

import "./AudioRecognitionHistoryList.scss";
import { ImageNoGenerations } from "../../../Components/Icons/ImageNoGenerations";

export const AudioRecognitionHistoryList: React.FC = () => {
  const [favorites, setFavorites] = useState<boolean>(false);
  const [neronet, setNeronet] = useState<any>();
  const [range, setRange] = useState<any>();
  const [q, setSearchQuery] = useState<string>();

  const [pagination, setPagination] = useState<any>({
    total: 1,
    pageSize: 20,
    pageSizeOptions: [10, 20, 50, 100],
    current: 1,
  });
  const [tableSorter, setSorter] = useState<any>({
    field: "created_at",
    order: "descend",
  });
  const { data, isLoading } = useGetChatsQuery({
    page: pagination.current,
    limit: pagination.pageSize,
    output_type: "audio_recognition",
    order: tableSorter.field,
    order_dir: tableSorter.order,
    q,
    from: range ? range[0]?.toDate().getTime() : undefined,
    to: range ? range[1]?.toDate().getTime() : undefined,
    favorites,
    neuronet_id: neronet ? neronet.split("_")[0] : undefined,
    option_id:
      neronet && neronet.split("_").length === 2
        ? neronet.split("_")[1]
        : undefined,
  });
  useEffect(() => {
    if (data) {
      setPagination({ ...pagination, total: data[1] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (!data) {
    return <>No data</>;
  }

  return (
    <Flex vertical className="audioRecognitionHistoryList">
      <Flex className="audioRecognitionHistoryListLabel">Результат</Flex>
      {data && data[1] > 0 && (
        <>
          <Flex className="chatMessageAudioTextContainer">
            {data[0].map((item: any) => {
              return (
                <ChatAudioTextCard mode="user" key={item.id} item={item} />
              );
            })}
          </Flex>
          <Pagination
            onChange={(page) => {
              setPagination({ ...pagination, page });
            }}
            align="end"
            defaultCurrent={pagination.page}
            pageSize={pagination.limit}
            total={pagination.total}
          />
        </>
      )}
      {data && data[1] === 0 && (
        <div className="noGenerationsContainer">
          <span>
            <ImageNoGenerations />
          </span>
          <span className="noGenerationsTitle">У вас еще нет генераций</span>
          <span className="noGenerationsText">
            Начните работу с сервисом и получите свои первые результаты
          </span>
        </div>
      )}
    </Flex>
  );
};
