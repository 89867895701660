import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import AppLayout from './Components/admin/Layout/Layout';
import { menuItems } from './Components/admin/Menu/MenuItems';
import { Loader } from './Components/common/Loader';
import { UserRoleEnum } from './types/UserRole.enum';
import { useAppDispatch } from './store/store';

type PropsType = {
  user: any|null,
  isLoading: boolean
}

export const ProtectedRoute: React.FC<PropsType> = ({
    user,
    isLoading,
    redirectPath = '/login',
  }: any) => {
    let location = useLocation();
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch({ type: 'socket/connect' })
    
        return () => {
          dispatch({ type: 'socket/disconnect' })
        }
      }, [dispatch, user])

    if(isLoading){
      return <Loader />
    }

    if (!user && !isLoading) {
      return <Navigate to={redirectPath} replace state={{from: location}} />;
    }
    
    if(user && user.role===UserRoleEnum.Member) {
      return <div>Forbidden</div>
    }

    return <AppLayout menuItems={menuItems}>
      <Outlet />
    </AppLayout> 
  };