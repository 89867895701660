import React from "react"
import { Select } from "antd"


const formatOptions: {value: string, title: string}[] = [
  {
    value: 'MP4',
    title: 'MP4'
  },
  {
    value: '3gp',
    title: '3GP'
  },
  {
    value: 'avi',
    title: 'AVI'
  },
  {
    value: 'mov',
    title: 'MOV'
  }
]


type PropsType = {
  value: string,
  onChange: (val: string)=>void
}

export const VizardFormatSelector: React.FC<PropsType> = ({value, onChange}) => {

  return (
    <>
      <Select value={value} onChange={(value) => onChange(value)}>
        {formatOptions.map((option) => {
          return (
            <Select.Option key={option.value} value={option.value}>
              {option.title}
            </Select.Option>
          );
        })}
      </Select>
    </>
  );
}