import React from "react";
import {
  NeuronetAccountKey,
  NeuronetAccountKeySecret,
} from "../../../../types/SaveNeuronet.type";
import { Input } from "antd";
import FormItem from "antd/es/form/FormItem";

type PropsType = {
  value: NeuronetAccountKeySecret;
  onChange: (val: NeuronetAccountKeySecret) => void;
};

export const AccountKeySecret: React.FC<PropsType> = ({ value, onChange }) => {
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };

  return (
    <>
      <FormItem
        {...formItemLayout}
        label={<span>Ключ&nbsp;</span>}
        hasFeedback>
        <Input value={value.key} onChange={(val)=>{onChange({...value, key: val.target.value})}} />
      </FormItem>
      <FormItem
        {...formItemLayout}
        label={<span>Secret&nbsp;</span>}
        hasFeedback>
        <Input value={value.secret} onChange={(val)=>{onChange({...value, secret: val.target.value})}}/>
      </FormItem>
    </>
  );
};
