import { Button, Flex, Input, Modal, Tag } from "antd";
import React, { useState } from "react";
import { SupportRequestItem, SupportRequestMessage } from "../../../types/SupportRequestItem.type";
import { SupportStatusEnum } from "../../../types/SupportStatus.enum";

import './SupportRequestDetails.scss'
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useCreateResponseMutation, useGetAdminSupportRequestQuery } from "../../../store/supportApiSlice";
import { Loader } from "../../../Components/common/Loader";
import { SupportCategoryEnum } from "../../../types/SupportCategory.enum";
import { SupportPriorityEnum } from "../../../types/SupportPriority.enum";
import { SupportMessage } from "../../admin/support/SupportMessage";

type PropsType = {
  open: boolean,
  item: SupportRequestItem
  close: ()=>void
}

export const SupportRequestDetails: React.FC<PropsType> = ({ open, item, close })=>{
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
  );

  const { data, isLoading } = useGetAdminSupportRequestQuery({id: item.id})
  const [ send ] = useCreateResponseMutation()
  const [answer, setAnswer] = useState<string>('')

  const sendSupportAnswer =async ()=>{
    const payload = {
      text: answer,
      reply_to: data.messages[data.messages.length-1].id,
      request_id: data.id
    }
    await send(payload)
    setAnswer('')


  }
  if(isLoading) return <Loader />

  return <Modal   getContainer={()=>{return document.getElementById('modals_container')||document.body}}  
  open={open} 
  width={'60%'} 
  height={'213px'} 
  onCancel={close}
  className={"supportRequestDetails "+ currentAlgorithm}
  title={<div className="supportRequestDetailsHeaders">
  <span>
    {data.status===SupportStatusEnum.OPEN && <Tag className="statusOpenTag">Открыта</Tag>}
    {data.status===SupportStatusEnum.CLOSED && <Tag className="statusClosedTag">Закрыта</Tag>}
    {data.category===SupportCategoryEnum.COMMON && <Tag  style={{height: '22px'}}>Общее</Tag>}
    {data.category===SupportCategoryEnum.FEEDBACK && <Tag  style={{height: '22px'}}>Отзывы</Tag>}
    {data.category===SupportCategoryEnum.OTHER && <Tag  style={{height: '22px'}}>Другие</Tag>}
    {data.category===SupportCategoryEnum.Suggestions && <Tag  style={{height: '22px'}}>Предложения</Tag>}
    {data.category===SupportCategoryEnum.TECH && <Tag  style={{height: '22px'}} color="geekblue">Технические отзывы</Tag>}
    
    {data.priority===SupportPriorityEnum.CRITICAL && <Tag  style={{height: '22px'}} color="red">Критический приоритет</Tag>}
    {data.priority===SupportPriorityEnum.HIGH && <Tag  style={{height: '22px'}} color="orange">Высокий приоритет</Tag>}
    {data.priority===SupportPriorityEnum.LOW && <Tag  style={{height: '22px'}}  color="blue">Низкий приоритет</Tag>}
    {data.priority===SupportPriorityEnum.NORMAL && <Tag  style={{height: '22px'}} color="green">Нормальный приоритет</Tag>}
    
  </span>
  <span>
    Тема обращения: <b>#{data.id} </b> {data.subject}
  </span>
  </div>}
  footer={null}
  >
    <Flex vertical>

      <div className="supportRequestDetailsBody">

        {data.messages.map((item: SupportRequestMessage)=>{
          return <SupportMessage item={item} />
        })}
      {/* <Flex vertical style={{width: '80%'}}> */}
        <Flex>Ответить</Flex>
        {/* <textarea
          onChange={(e)=>{setAnswer(e.target.value)}}
          style={{width: '100%', height:'100px'}}
          value={answer}>
        </textarea> */}
        <Input.TextArea rows={4} 
          onChange={(e)=>{setAnswer(e.target.value)}}
          style={{width: 'calc(80% + 48px)', padding: '12px', height:'200px', backgroundColor: 'transparent'}}
          value={answer}
        />
        <Flex style={{width: '86%', height: '64px', justifyContent: 'flex-end', marginTop: '24px'}}>
          <Button onClick={sendSupportAnswer}>Отправить ответ</Button>

        </Flex>
      {/* </Flex> */}
      </div>
    </Flex>
    
  </Modal>
}